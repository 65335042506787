export const INFECTION_MOCK_DATA = {
    infections: [
        {
            name: 'Units under Outbreak:',
            count: 5,
            units: ['K6', 'C3', 'K8', 'C4 (C8)', 'D6'],
        },
        {
            name: 'Units requiring Noco to every discharge:',
            count: 1,
            units: ['K8'],
        },
        {
            name: 'Units closed to admission:',
            count: 3,
            units: ['K6', 'D7/CD7', 'D6'],
        },
    ],
}

export const WARD_SECTIONS_MOCK_DATA = {
    sections: [
        'Cardiology',
        'Palliative',
        'Family Med',
        'HAD',
        'Special Units',
        'Geriatrics',
        'MSICU',
        'Neurology',
        'Psychiatry',
        'Oncology',
        'IM non Teach',
        'Surgery',
        'SSU',
        'IM Teach',
    ],
}

export const NEW_WARD_SECTIONS_MOCK_DATA = {
    sections: [
        'V2',
        'HD6',
        'K1PD',
        'D53N',
        'K6',
        'K3HR',
        'C3',
        'C48W',
        'K3FB',
        'CD7',
        'K1PS',
        'KS1R',
        'K3NN',
        'B3IU',
        '5W',
        'K10',
        'B3AU',
        'B3HC',
        'D41',
        'K7',
        'K9',
        'K2CU',
        'D6',
        '4M',
        'NURS',
        'K1IC',
        'K8',
        'C61',
        'K2CI',
    ],
}
