import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Loader from '../components/Loader/Loader' // Adjust relative imports
import PrivateRoute from './PrivateRoute'
import { Link } from 'react-router-dom'
import ErrorPage from 'pages/ErrorPage/ErrorPage'

// Error Fallback component for ErrorBoundary
const ErrorFallback = () => (
    <div role="alert">
        <p>Something went wrong. Please try refreshing the page.</p>
        <p>Or</p>
        <Link to="/">Go back to Home</Link>
    </div>
)

// Utility function to wrap components with Suspense and ErrorBoundary
export const withSuspenseAndErrorBoundary = (
    Component: React.ComponentType
) => (
    // <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<Loader />}>
            <Component />
        </Suspense>
    </ErrorBoundary>
)

// Utility function to wrap protected routes with optional role-based access
export const protectedRoute = (
    Component: React.ComponentType,
    allowedRoles?: string[]
) => (
    <PrivateRoute allowedRoles={allowedRoles}>
        {withSuspenseAndErrorBoundary(Component)}
    </PrivateRoute>
)
