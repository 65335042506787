import { useKeycloak } from '@react-keycloak/web'
import { ReactNode, useEffect } from 'react'
import Loader from 'components/Loader/Loader'
import Login from 'pages/Login/Login'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import ErrorPage from 'pages/ErrorPage/ErrorPage'

interface PrivateRouteProps {
    children: ReactNode
    allowedRoles?: string[] // Optional array of allowed roles
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    children,
    allowedRoles,
}) => {
    // const { keycloak, initialized } = useKeycloak()
    const auth = useAuth()

    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading
        ) {
            auth.signinRedirect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect,
    ])

    const styleContainer: React.CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    switch (auth.activeNavigator) {
        case 'signinSilent':
            console.log('signinSilent')
            return (
                <div style={styleContainer}>
                    <h1>Signing you in...</h1>
                </div>
            )
        case 'signoutRedirect':
            console.log('signoutRedirect')
            return (
                <div style={styleContainer}>
                    <h1>Signing you out...</h1>
                </div>
            )
    }

    // Get user roles from the Keycloak token
    // const userRoles: string[] = keycloak?.tokenParsed?.realm_access?.roles || []
    const userRoles: string[] = ['admin']

    // Check if the user has the allowed roles
    const hasAccess = allowedRoles
        ? allowedRoles.some((role) => userRoles.includes(role))
        : true

    if (auth.isLoading) {
        return <Loader />
    }

    // If not authenticated, redirect to login
    if (!auth.isAuthenticated) {
        return <Login />
        // return <div>Unable to log in</div>
    }

    if (auth.error) {
        if (auth.error) {
            if (auth.error.message === 'login_required') {
                console.error('User is not logged in. Redirecting to login...')
                auth.signinRedirect() // Initiates the login process
                return null // Render nothing while redirecting
            }

            // Log other errors and show an error page
            console.error('Authentication error:', auth.error)
            return <ErrorPage />
        }
    }

    const styles: React.CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '40px',
    }
    // If authenticated but not authorized for the allowed roles, show unauthorized message
    if (!hasAccess) {
        return (
            <div style={styles}>
                You do not have permission to view this page.
            </div>
        )
    }

    // Render the protected content
    return <>{children}</>
}

export default PrivateRoute
