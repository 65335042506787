import { UnitAnalyticsData } from 'redux/rtk/corporateView/types'

export const ANALITICS_MOCK_DATA: UnitAnalyticsData[] = [
    {
        unitCensusCode: 'CHIR',
        patientsCount: 169,
        unitCensusCapacity: 117,
        dailyAdmissionCount: 55,
        medianLengthOfStayDays: 2,
        dischargeCount: 74,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 199,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 202,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 215,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 113,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 111,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 226,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 169,
            },
        ],
        predictionsNextDays: ['161', '156', '150'],
        predictionLengthOfStayNextDays: ['3', '4', '4'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 6,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 0,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 2,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 16,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 58,
    },
    {
        unitCensusCode: 'CSUR',
        patientsCount: 45,
        unitCensusCapacity: 50,
        dailyAdmissionCount: 1,
        medianLengthOfStayDays: 8,
        dischargeCount: 2,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 47,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 46,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 46,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 48,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 42,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 48,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 45,
            },
        ],
        predictionsNextDays: ['46', '46', '46'],
        predictionLengthOfStayNextDays: ['8', '9', '9'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 4,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 5.5,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 5,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 6,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 7.5,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 8,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 2,
    },
    {
        unitCensusCode: 'FAM',
        patientsCount: 20,
        unitCensusCapacity: 21,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 5.5,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 20,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 20,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 21,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 21,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 21,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 22,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 20,
            },
        ],
        predictionsNextDays: ['22', '22', '22'],
        predictionLengthOfStayNextDays: ['7', '6', '6'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 9.5,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 6.5,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 5.5,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'GER',
        patientsCount: 64,
        unitCensusCapacity: 63,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 60,
        dischargeCount: 80,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 65,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 65,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 66,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 66,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 66,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 65,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 64,
            },
        ],
        predictionsNextDays: ['66', '66', '66'],
        predictionLengthOfStayNextDays: ['60', '59', '57'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 69,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 70,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 68.5,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 64,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 65,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 61,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 60,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 80,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'HAD',
        patientsCount: 11,
        unitCensusCapacity: 5,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 10,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 9,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 9,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 10,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 10,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 9,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 11,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 11,
            },
        ],
        predictionsNextDays: ['11', '11', '11'],
        predictionLengthOfStayNextDays: ['9', '9', '8'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 11,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 12,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 10.5,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 9,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 10,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 10,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'IM_NON_TEACH',
        patientsCount: 29,
        unitCensusCapacity: 28,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 11,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 30,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 29,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 30,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 30,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 30,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 29,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 29,
            },
        ],
        predictionsNextDays: ['29', '29', '29'],
        predictionLengthOfStayNextDays: ['11', '11', '11'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 10.5,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 12,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 10.5,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 9.5,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 10.5,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 11,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 11,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'IM_TEACH',
        patientsCount: 66,
        unitCensusCapacity: 64,
        dailyAdmissionCount: 3,
        medianLengthOfStayDays: 12,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 68,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 66,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 68,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 66,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 68,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 67,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 66,
            },
        ],
        predictionsNextDays: ['67', '66', '66'],
        predictionLengthOfStayNextDays: ['13', '13', '13'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 13.5,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 12,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 12,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 13,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 13,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 14,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 12,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'NEUR',
        patientsCount: 25,
        unitCensusCapacity: 22,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 9,
        dischargeCount: 40,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 26,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 25,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 26,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 26,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 25,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 25,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 25,
            },
        ],
        predictionsNextDays: ['25', '25', '25'],
        predictionLengthOfStayNextDays: ['10', '11', '11'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 7.5,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 9,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 10,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 11,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 9,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 40,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'ONC',
        patientsCount: 28,
        unitCensusCapacity: 28,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 21.5,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 27,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 28,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 28,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 28,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 27,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 27,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 28,
            },
        ],
        predictionsNextDays: ['28', '28', '28'],
        predictionLengthOfStayNextDays: ['21', '22', '22'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 19,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 19,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 20,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 19.5,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 20,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 21,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 21.5,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'PALL',
        patientsCount: 15,
        unitCensusCapacity: 16,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 33,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 13,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 13,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 15,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 14,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 15,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 15,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 15,
            },
        ],
        predictionsNextDays: ['15', '16', '16'],
        predictionLengthOfStayNextDays: ['34', '35', '36'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 27,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 29,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 30,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 32.5,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 32,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 33,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 33,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'PSY',
        patientsCount: 55,
        unitCensusCapacity: 52,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 69,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 56,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 56,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 56,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 55,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 55,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 55,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 55,
            },
        ],
        predictionsNextDays: ['55', '55', '54'],
        predictionLengthOfStayNextDays: ['67', '67', '66'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 76,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 67,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 68,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 67,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 68,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 69,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 69,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
    {
        unitCensusCode: 'SI',
        patientsCount: 27,
        unitCensusCapacity: 27,
        dailyAdmissionCount: 0,
        medianLengthOfStayDays: 9,
        dischargeCount: 2,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 26,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 23,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 21,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 22,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 22,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 23,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 27,
            },
        ],
        predictionsNextDays: ['24', '24', '24'],
        predictionLengthOfStayNextDays: ['15', '15', '15'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 8.5,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 13,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 14,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 18.5,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 19.5,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 14,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 9,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 2,
    },
    {
        unitCensusCode: 'SPECIAL_UNITS',
        patientsCount: 36,
        unitCensusCapacity: 51,
        dailyAdmissionCount: 7,
        medianLengthOfStayDays: 1,
        dischargeCount: 2,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 44,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 44,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 49,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 35,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 26,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 35,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 36,
            },
        ],
        predictionsNextDays: ['32', '30', '27'],
        predictionLengthOfStayNextDays: ['1', '1', '1'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 2,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 1.5,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 1,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 1,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 2,
    },
    {
        unitCensusCode: 'SSU',
        patientsCount: 24,
        unitCensusCapacity: 23,
        dailyAdmissionCount: 1,
        medianLengthOfStayDays: 8,
        dischargeCount: 0,
        historyUnitGroupCount: [
            {
                computationDate: '2024-11-27T00:00:00',
                count: 24,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                count: 24,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                count: 24,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                count: 23,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                count: 23,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                count: 23,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                count: 24,
            },
        ],
        predictionsNextDays: ['23', '23', '23'],
        predictionLengthOfStayNextDays: ['10', '10', '10'],
        historyMedianLengthOfStayDays: [
            {
                computationDate: '2024-11-27T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-11-28T00:00:00',
                medianLengthOfStayDays: 7,
            },
            {
                computationDate: '2024-11-29T00:00:00',
                medianLengthOfStayDays: 8,
            },
            {
                computationDate: '2024-11-30T00:00:00',
                medianLengthOfStayDays: 10,
            },
            {
                computationDate: '2024-12-01T00:00:00',
                medianLengthOfStayDays: 11,
            },
            {
                computationDate: '2024-12-02T00:00:00',
                medianLengthOfStayDays: 10,
            },
            {
                computationDate: '2024-12-03T00:00:00',
                medianLengthOfStayDays: 8,
            },
        ],
        lastModifiedDate: '2024-12-03T11:47:39.995177',
        otherDestinationCount: 0,
        chlsdDestinationCount: 0,
        rehabDestinationCount: 0,
        homeDischargeCount: 0,
    },
]

export const UNIT_GROUPS_MOCK = {
    unitGroups: [
        {
            name: 'CHIR',
            capacity: 117,
            units: [
                {
                    unit_code: 'C48W',
                    unit_capacity: 35,
                },
                {
                    unit_code: 'D53N',
                    unit_capacity: 18,
                },
                {
                    unit_code: 'K1PD',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'K1PS',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'K8',
                    unit_capacity: 32,
                },
                {
                    unit_code: 'K9',
                    unit_capacity: 32,
                },
                {
                    unit_code: 'KS1R',
                    unit_capacity: 0,
                },
            ],
        },
        {
            name: 'CSUR',
            capacity: 50,
            units: [
                {
                    unit_code: 'K2CI',
                    unit_capacity: 14,
                },
                {
                    unit_code: 'K2CL',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'K2CU',
                    unit_capacity: 36,
                },
            ],
        },
        {
            name: 'FAM',
            capacity: 21,
            units: [
                {
                    unit_code: 'C3',
                    unit_capacity: 21,
                },
            ],
        },
        {
            name: 'GER',
            capacity: 63,
            units: [
                {
                    unit_code: 'D6',
                    unit_capacity: 28,
                },
                {
                    unit_code: 'HD6',
                    unit_capacity: 35,
                },
            ],
        },
        {
            name: 'HAD',
            capacity: 5,
            units: [
                {
                    unit_code: 'V1',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'V2',
                    unit_capacity: 5,
                },
                {
                    unit_code: 'V4',
                    unit_capacity: 0,
                },
            ],
        },
        {
            name: 'IM_NON_TEACH',
            capacity: 28,
            units: [
                {
                    unit_code: 'CD7',
                    unit_capacity: 28,
                },
            ],
        },
        {
            name: 'IM_TEACH',
            capacity: 64,
            units: [
                {
                    unit_code: 'K6',
                    unit_capacity: 32,
                },
                {
                    unit_code: 'K7',
                    unit_capacity: 32,
                },
            ],
        },
        {
            name: 'NEUR',
            capacity: 24,
            units: [
                {
                    unit_code: 'K10',
                    unit_capacity: 24,
                },
            ],
        },
        {
            name: 'ONC',
            capacity: 28,
            units: [
                {
                    unit_code: 'D41',
                    unit_capacity: 28,
                },
            ],
        },
        {
            name: 'PALL',
            capacity: 16,
            units: [
                {
                    unit_code: '4M',
                    unit_capacity: 16,
                },
            ],
        },
        {
            name: 'PSY',
            capacity: 52,
            units: [
                {
                    unit_code: 'B3AU',
                    unit_capacity: 28,
                },
                {
                    unit_code: 'B3HC',
                    unit_capacity: 8,
                },
                {
                    unit_code: 'B3IU',
                    unit_capacity: 12,
                },
                {
                    unit_code: 'C3UB',
                    unit_capacity: 4,
                },
            ],
        },
        {
            name: 'SI',
            capacity: 27,
            units: [
                {
                    unit_code: 'K1IC',
                    unit_capacity: 27,
                },
            ],
        },
        {
            name: 'SPECIAL_UNITS',
            capacity: 51,
            units: [
                {
                    unit_code: '5W',
                    unit_capacity: 40,
                },
                {
                    unit_code: 'K3FB',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'K3HR',
                    unit_capacity: 11,
                },
                {
                    unit_code: 'K3NN',
                    unit_capacity: 0,
                },
                {
                    unit_code: 'NURS',
                    unit_capacity: 0,
                },
            ],
        },
        {
            name: 'SSU',
            capacity: 23,
            units: [
                {
                    unit_code: 'C61',
                    unit_capacity: 23,
                },
            ],
        },
    ],
}
