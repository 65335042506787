import { useLocation } from 'react-router-dom'

export const useIsWardV2 = () => {
    const location = useLocation()
    return location.pathname.includes('ward-v2')
}

export const sortAlphabetically = (arr: string[]): string[] => {
    return [...arr].sort((a, b) => a.localeCompare(b))
}
