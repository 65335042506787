import { generateDate, generateFurureDate } from '../date/generateDate'
// {
//     "date": generateDate(11),
//     "value": 15.22
// },
// {
//     "date": generateDate(10),
//     "value": 16.91
// },
// {
//     "date": generateDate(9),
//     "value": 15.37
// },
// {
//     "date": generateDate(8),
//     "value": 15.37
// },
// {
//     "date": generateDate(7),
//     "value": 13.97
// },
// {
//     "date": generateDate(6),
//     "value": 15.52
// },
// {
//     "date": generateDate(5),
//     "value": 14.11
// },
// {
//     "date": generateDate(4),
//     "value": 15.68
// },
// {
//     "date": generateDate(3),
//     "value": 17.42
// },
// {
//     "date": generateDate(2),
//     "value": 15.84
// },
// {
//     "date": generateDate(1),
//     "value": 14.4
// },
// {
//     "date": generateDate(0),
//     "value": 14.4
// },
// {
//     "date": generateDate(0),
//     "value": 14.4
// }

export const EmergencyDepartmentDataMock = {
    stretcherSection: {
        loS: {
            currentValue: '17h',
            avgValue: 0,
            positive: false,
            values: [
                {
                    date: generateDate(11),
                    value: 17,
                },
                {
                    date: generateDate(10),
                    value: 13,
                },
                {
                    date: generateDate(9),
                    value: 10.5,
                },
                {
                    date: generateDate(8),
                    value: 6.9,
                },
                {
                    date: generateDate(7),
                    value: 3.9,
                },
                {
                    date: generateDate(6),
                    value: 1.6,
                },
                {
                    date: generateDate(5),
                    value: 14.1,
                },
                {
                    date: generateDate(4),
                    value: 22.2,
                },
                {
                    date: generateDate(3),
                    value: 22.3,
                },
                {
                    date: generateDate(2),
                    value: 18.3,
                },
                {
                    date: generateDate(1),
                    value: 16.5,
                },
                {
                    date: generateDate(0),
                    value: 18,
                },
            ],
        },
        loSOver75: {
            currentValue: '17.2h',
            avgValue: 0,
            positive: false,
            values: [
                {
                    date: generateDate(11),
                    value: 17.2,
                },
                {
                    date: generateDate(10),
                    value: 13.2,
                },
                {
                    date: generateDate(9),
                    value: 10.1,
                },
                {
                    date: generateDate(8),
                    value: 6.1,
                },
                {
                    date: generateDate(7),
                    value: 3.2,
                },
                {
                    date: generateDate(6),
                    value: 1.4,
                },
                {
                    date: generateDate(5),
                    value: 1.4,
                },
                {
                    date: generateDate(4),
                    value: 16.8,
                },
                {
                    date: generateDate(3),
                    value: 16.4,
                },
                {
                    date: generateDate(2),
                    value: 12.4,
                },
                {
                    date: generateDate(1),
                    value: 8.4,
                },
                {
                    date: generateDate(0),
                    value: 10.3,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '89',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 89,
                },
                {
                    date: generateDate(10),
                    value: 89,
                },
                {
                    date: generateDate(9),
                    value: 78,
                },
                {
                    date: generateDate(8),
                    value: 75,
                },
                {
                    date: generateDate(7),
                    value: 67,
                },
                {
                    date: generateDate(6),
                    value: 53,
                },
                {
                    date: generateDate(5),
                    value: 33,
                },
                {
                    date: generateDate(4),
                    value: 21,
                },
                {
                    date: generateDate(3),
                    value: 18,
                },
                {
                    date: generateDate(2),
                    value: 18,
                },
                {
                    date: generateDate(1),
                    value: 17,
                },
                {
                    date: generateDate(0),
                    value: 14,
                },
            ],
        },
    },
    ambulatorySection: {
        loS: {
            currentValue: '1.7h',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 1.7,
                },
                {
                    date: generateDate(10),
                    value: -2.3,
                },
                {
                    date: generateDate(9),
                    value: 0.4,
                },
                {
                    date: generateDate(8),
                    value: -3,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
        loSUnder4Hours: {
            currentValue: '73.7%',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 73.7,
                },
                {
                    date: generateDate(10),
                    value: 84.2,
                },
                {
                    date: generateDate(9),
                    value: 100,
                },
                {
                    date: generateDate(8),
                    value: 100,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '19',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 19,
                },
                {
                    date: generateDate(10),
                    value: 19,
                },
                {
                    date: generateDate(9),
                    value: 5,
                },
                {
                    date: generateDate(8),
                    value: 3,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
    },
    admDelay: {
        currentValue: '34.3%',
        avgValue: 0,
        positive: true,
        values: [
            {
                date: generateDate(11),
                value: 34.3,
            },
            {
                date: generateDate(10),
                value: 30.6,
            },
            {
                date: generateDate(9),
                value: 32.5,
            },
            {
                date: generateDate(8),
                value: 23.1,
            },
            {
                date: generateDate(7),
                value: 22.4,
            },
            {
                date: generateDate(6),
                value: 26.4,
            },
            {
                date: generateDate(5),
                value: 39.4,
            },
            {
                date: generateDate(4),
                value: 52.4,
            },
            {
                date: generateDate(3),
                value: 61.1,
            },
            {
                date: generateDate(2),
                value: 50,
            },
            {
                date: generateDate(1),
                value: 41.2,
            },
            {
                date: generateDate(0),
                value: 35.7,
            },
        ],
    },
    preProd: {
        currentValue: '0',
        avgValue: 0,
        positive: false,
        values: [
            {
                date: generateDate(11),
                value: 5,
            },
            {
                date: generateDate(10),
                value: 0,
            },
            {
                date: generateDate(9),
                value: 0,
            },
            {
                date: generateDate(8),
                value: 0,
            },
            {
                date: generateDate(7),
                value: 0,
            },
            {
                date: generateDate(6),
                value: 0,
            },
            {
                date: generateDate(5),
                value: 0,
            },
            {
                date: generateDate(4),
                value: 0,
            },
            {
                date: generateDate(3),
                value: 0,
            },
            {
                date: generateDate(2),
                value: 0,
            },
            {
                date: generateDate(1),
                value: 0,
            },
            {
                date: generateDate(0),
                value: 0,
            },
        ],
    },
    preProdMoreThen1: {
        currentValue: '0%',
        avgValue: 0,
        positive: true,
        values: [
            {
                date: generateDate(11),
                value: 0,
            },
            {
                date: generateDate(10),
                value: 0,
            },
            {
                date: generateDate(9),
                value: 0,
            },
            {
                date: generateDate(8),
                value: 0,
            },
            {
                date: generateDate(7),
                value: 0,
            },
            {
                date: generateDate(6),
                value: 0,
            },
            {
                date: generateDate(5),
                value: 0,
            },
            {
                date: generateDate(4),
                value: 0,
            },
            {
                date: generateDate(3),
                value: 0,
            },
            {
                date: generateDate(2),
                value: 0,
            },
            {
                date: generateDate(1),
                value: 0,
            },
            {
                date: generateDate(0),
                value: 0,
            },
        ],
    },
}

export const ED_DEMOGRAPHICS_MOCK = {
    stretchersCount: '106',
    stretchersMedianLengthOfStay: '19h',
    stretchersMore75MedianLengthOfStay: '36h',
    ambulatoriesCount: '74',
    ambulatoriesMedianLengthOfStay: '2h',
    countAmbulatoriesLengthOfStayBelow4: '93%',
    countAdmissionLengthOfStayAbove2: '91%',
    prePodsCount: '20',
    prePodsCountPrePodLengthOfStayAbove1: '100%',
    edDemographicGraphs: [
        {
            point_time: '2024-10-21T15:27:34.762345',
            stretchers_count: 106,
            stretchers_median_length_of_stay: 19,
            stretchers_more_75_median_length_of_stay: 36,
            ambulatories_count: 74,
            ambulatories_median_length_of_stay: 2,
            percentage_ambulatories_length_of_stay_below_4: 93,
            percentage_admission_length_of_stay_above_2: 91,
            pre_pods_count: 20,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-21T11:27:34.762345',
            stretchers_count: 123,
            stretchers_median_length_of_stay: 16,
            stretchers_more_75_median_length_of_stay: 32,
            ambulatories_count: 20,
            ambulatories_median_length_of_stay: 1,
            percentage_ambulatories_length_of_stay_below_4: 100,
            percentage_admission_length_of_stay_above_2: 92,
            pre_pods_count: 18,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-21T07:27:34.762345',
            stretchers_count: 116,
            stretchers_median_length_of_stay: 20,
            stretchers_more_75_median_length_of_stay: 36,
            ambulatories_count: 2,
            ambulatories_median_length_of_stay: 0,
            percentage_ambulatories_length_of_stay_below_4: 50,
            percentage_admission_length_of_stay_above_2: 97,
            pre_pods_count: 15,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-21T03:27:34.762345',
            stretchers_count: 107,
            stretchers_median_length_of_stay: 24,
            stretchers_more_75_median_length_of_stay: 32,
            ambulatories_count: 9,
            ambulatories_median_length_of_stay: 4,
            percentage_ambulatories_length_of_stay_below_4: 44,
            percentage_admission_length_of_stay_above_2: 100,
            pre_pods_count: 15,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-20T23:27:34.762345',
            stretchers_count: 99,
            stretchers_median_length_of_stay: 25,
            stretchers_more_75_median_length_of_stay: 29,
            ambulatories_count: 7,
            ambulatories_median_length_of_stay: 2,
            percentage_ambulatories_length_of_stay_below_4: 100,
            percentage_admission_length_of_stay_above_2: 94,
            pre_pods_count: 16,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-20T19:27:34.762345',
            stretchers_count: 128,
            stretchers_median_length_of_stay: 11,
            stretchers_more_75_median_length_of_stay: 27,
            ambulatories_count: 6,
            ambulatories_median_length_of_stay: 2,
            percentage_ambulatories_length_of_stay_below_4: 67,
            percentage_admission_length_of_stay_above_2: 95,
            pre_pods_count: 25,
            percentage_pre_pod_length_of_stay_above_1: 96,
        },
        {
            point_time: '2024-10-20T15:27:34.762345',
            stretchers_count: 146,
            stretchers_median_length_of_stay: 10,
            stretchers_more_75_median_length_of_stay: 24,
            ambulatories_count: 9,
            ambulatories_median_length_of_stay: 2,
            percentage_ambulatories_length_of_stay_below_4: 78,
            percentage_admission_length_of_stay_above_2: 96,
            pre_pods_count: 26,
            percentage_pre_pod_length_of_stay_above_1: 85,
        },
        {
            point_time: '2024-10-20T11:27:34.762345',
            stretchers_count: 135,
            stretchers_median_length_of_stay: 17,
            stretchers_more_75_median_length_of_stay: 21,
            ambulatories_count: 3,
            ambulatories_median_length_of_stay: 0,
            percentage_ambulatories_length_of_stay_below_4: 100,
            percentage_admission_length_of_stay_above_2: 99,
            pre_pods_count: 21,
            percentage_pre_pod_length_of_stay_above_1: 95,
        },
        {
            point_time: '2024-10-20T07:27:34.762345',
            stretchers_count: 113,
            stretchers_median_length_of_stay: 17,
            stretchers_more_75_median_length_of_stay: 19,
            ambulatories_count: 16,
            ambulatories_median_length_of_stay: 9,
            percentage_ambulatories_length_of_stay_below_4: 6,
            percentage_admission_length_of_stay_above_2: 99,
            pre_pods_count: 19,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-20T03:27:34.762345',
            stretchers_count: 102,
            stretchers_median_length_of_stay: 15,
            stretchers_more_75_median_length_of_stay: 15,
            ambulatories_count: 19,
            ambulatories_median_length_of_stay: 5,
            percentage_ambulatories_length_of_stay_below_4: 26,
            percentage_admission_length_of_stay_above_2: 99,
            pre_pods_count: 20,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-19T23:27:34.762345',
            stretchers_count: 101,
            stretchers_median_length_of_stay: 12,
            stretchers_more_75_median_length_of_stay: 13,
            ambulatories_count: 22,
            ambulatories_median_length_of_stay: 1,
            percentage_ambulatories_length_of_stay_below_4: 77,
            percentage_admission_length_of_stay_above_2: 91,
            pre_pods_count: 18,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-19T19:27:34.762345',
            stretchers_count: 116,
            stretchers_median_length_of_stay: 10,
            stretchers_more_75_median_length_of_stay: 13,
            ambulatories_count: 22,
            ambulatories_median_length_of_stay: 3,
            percentage_ambulatories_length_of_stay_below_4: 55,
            percentage_admission_length_of_stay_above_2: 91,
            pre_pods_count: 19,
            percentage_pre_pod_length_of_stay_above_1: 100,
        },
        {
            point_time: '2024-10-19T15:27:34.762345',
            stretchers_count: 117,
            stretchers_median_length_of_stay: 17,
            stretchers_more_75_median_length_of_stay: 21,
            ambulatories_count: 26,
            ambulatories_median_length_of_stay: 2,
            percentage_ambulatories_length_of_stay_below_4: 58,
            percentage_admission_length_of_stay_above_2: 91,
            pre_pods_count: 21,
            percentage_pre_pod_length_of_stay_above_1: 95,
        },
    ],
    lastModifiedDate: '2024-10-21T19:27:34.762345',
}
