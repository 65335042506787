import { useMemo } from 'react'
import { useGetTranslationsDataQuery } from 'redux/rtk/wardView/wardViewApi'

// Custom Hook for Translating Categories
export const useTranslateCategories = (
    categories: (string | null)[],
    language: 'french' | 'english'
) => {
    const { data, error, isLoading } = useGetTranslationsDataQuery()

    // Memoize the translation logic to prevent unnecessary recalculations
    const translatedCategories = useMemo(() => {
        if (!data) return categories // Return original categories if data is not loaded

        return categories.map((category) => {
            if (!category) return null // Handle null values, returning null for those cases

            const translation = data.translations.find(
                (t) => t.category === category
            )
            return translation ? translation[language] : category // return original category if no translation
        })
    }, [data, categories, language])

    return { translatedCategories, error, isLoading }
}
