import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import qs from 'qs'
import { useParams } from 'react-router-dom'
import {
    selectCustomFilter,
    selectDepartmenSearch,
    selectGenderFilter,
    selectHospitalIdSearch,
    selectPatientNameSearch,
    selectSortBy,
    selectSortOrder,
} from '../redux/rtk/filter/selectors'
import { useAuth } from 'react-oidc-context'

const useQueryBuilder = () => {
    const { department } = useParams()
    const { isAuthenticated } = useAuth()
    const [queryString, setQueryString] = useState('')
    const customFilter = useSelector(selectCustomFilter)
    const departmenSearch = useSelector(selectDepartmenSearch)
    const patientNameSearch = useSelector(selectPatientNameSearch)
    const hospitalIdSearch = useSelector(selectHospitalIdSearch)
    const SortBy = useSelector(selectSortBy)
    const SortOrder = useSelector(selectSortOrder)
    const genderFilter = useSelector(selectGenderFilter)

    let parsedFilter = JSON.parse(JSON.stringify(customFilter))
    let discharge: any
    if (parsedFilter.hasOwnProperty('disch_today')) {
        discharge = parsedFilter.disch_today.status
    }
    if (parsedFilter.hasOwnProperty('disch_within')) {
        discharge = parsedFilter.disch_within.status
    }
    if (parsedFilter.hasOwnProperty('disch_over')) {
        discharge = parsedFilter.disch_over.status
    }

    let departmenSearchParsed = JSON.parse(JSON.stringify(departmenSearch))
    let data = {
        Name: patientNameSearch || undefined,
        HospitalId: hospitalIdSearch || undefined,
        Departments: departmenSearchParsed || undefined,
        HasPendingLabResults: parsedFilter?.pending?.status || undefined,
        HasAlerts: parsedFilter?.alerts?.status || undefined,
        HasOpenTasks: parsedFilter?.tasks?.status || undefined,
        HasHazards: parsedFilter?.hazards?.status || undefined,
        DischargeGroup: discharge || undefined,
        DischargeInDays: undefined,
        SortBy: SortBy,
        SortOrder: SortOrder,
        Department: department,
        genders: genderFilter || undefined,
    }

    useEffect(() => {
        if (isAuthenticated) {
            const query = qs.stringify(data, {
                encode: false,
                indices: false,
            })
            setQueryString(query)
        }
    }, [
        customFilter,
        departmenSearch,
        SortBy,
        SortOrder,
        patientNameSearch,
        hospitalIdSearch,
    ])

    return { queryString }
}

export default useQueryBuilder
