import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectToken } from '../../../redux/rtk/auth/selectors'
import {
    selectActiveUserRole,
    selectLocale,
} from '../../../redux/rtk/user/selectors'
import { selectActiveDepartment } from '../../../redux/rtk/filter/selectors'
import {
    resetFilter,
    setActiveDepartment,
    setUnitsOptions,
} from '../../../redux/rtk/filter/filterSlice'
import { setPatientsDataAPI } from '../../../redux/rtk/patientsTable/patientsTableSlice'
import NavigationDropdown from './NavigationDropdown/NavigationDropdown'

import styles from './Menu.module.scss'
import NavigationPages from './NavigationPages/NavigationPages'
import { NavigationItem } from '../MenuOptions'
import useMaxVisibleDepartments from '../../../hooks/useMaxVisibleDepartments'
import { useGetShowTermsOfServiceQuery } from '../../../redux/rtk/terms/termsConditionsSlice'
import { selectIsAcceptedTerms } from '../../../redux/rtk/termsAndOther/selectors'
import { useConditionalQuery } from '../../../hooks'
import { sortAlphabetically, useIsWardV2 } from './helper'
import { useGetWardSectionsQuery } from 'redux/rtk/units/unitsApi'
import { useAuth } from 'react-oidc-context'

const MOCK_DEPARTMENTS = {
    departments: [
        'Cardiology',
        'Psychiatry',
        'Family Med',
        'Palliative',
        'Oncology',
        'HAD',
        'Special Units',
        'IM non Teach',
        'Geriatrics',
        'Surgery',
        'MSICU',
        'SSU',
        'Neurology',
        'IM Teach',
    ],
}
type MenuProps = {
    menuItems: NavigationItem[]
}

const MAX_VISIBLE_WIDTH = 1000

const Menu: React.FC<MenuProps> = ({ menuItems }) => {
    const dispatch = useDispatch()
    const userToken = useSelector(selectToken)
    const locale = useSelector(selectLocale)
    const activeUserRole = useSelector(selectActiveUserRole)
    const isAcceptedTerms = useSelector(selectIsAcceptedTerms)
    const activeDepartment = useSelector(selectActiveDepartment)
    const { t, i18n } = useTranslation()
    const { isAuthenticated } = useAuth()
    // const [getDepartmentsTabs, { isSuccess, data }] = useLazyGetDepartmentsTabsQuery()
    // const [getUnits, { data: unitsData }] = useLazyGetUnitsQuery()
    const { data: termsAndConditionsData } = useGetShowTermsOfServiceQuery(
        undefined,
        {
            skip: !userToken,
        }
    )
    const componentRef = useRef<HTMLDivElement>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const { department } = useParams<{ department: string }>()

    const [tabItems, setTabItems] = useState<Array<NavigationItem>>([])
    const [visibleDepartments, setVisibleDepartments] = useState<
        Array<NavigationItem>
    >([])
    const [hiddenDepartments, setHiddenDepartments] = useState<
        Array<NavigationItem>
    >([])

    const fetchContitions = useMemo(
        () => [!!userToken, !!activeUserRole, isAcceptedTerms],
        [userToken, activeUserRole, termsAndConditionsData, isAcceptedTerms]
    )

    const { data, error, isFetching, isSuccess } = useConditionalQuery(
        useGetWardSectionsQuery,
        undefined,
        fetchContitions
    )

    const maxVisibleDepartment = useMaxVisibleDepartments()

    const maxVisibleWidth = 1000
    // useLayoutEffect(() => {
    //     if (!userToken) return
    //     if (!activeUserRole) return
    //     if (isAcceptedTerms) {
    //         // getDepartmentsTabs({})
    //     }
    // }, [userToken, termsAndConditionsData, isAcceptedTerms, activeUserRole])

    // useEffect(() => {
    //     if (department) {
    //         getUnits(department)
    //     }
    // }, [department])

    // useEffect(() => {
    //     if (unitsData) {
    //         dispatch(setUnitsOptions(unitsData))
    //     }
    // }, [unitsData, dispatch])
    useEffect(() => {
        const shouldRedirect =
            ['/default', '/dashboard'].includes(location.pathname) || !exists
        if (shouldRedirect) navigate(`/management_status`)
    }, [location])

    // let sortedSections = useMemo(
    //     () =>
    //         data && data?.sections?.length > 0
    //             ? sortAlphabetically(data?.sections)
    //             : [],
    //     [data]
    // )
    // console.log('🚀 ~ sortedSections:', sortedSections)

    const isWardV2 = useIsWardV2()
    const updateMenuItems = useCallback(() => {
        if (isSuccess && data && data?.sections?.length > 0) {
            let sortedSections = sortAlphabetically(data?.sections)
            let newTabs = []
            newTabs = sortedSections
                ?.filter((department: string) => department.toLowerCase())
                .map((department: string) => {
                    return {
                        title: department,
                        // link: `/${isWardV2 ? 'ward-v2' : 'ward'}/${department}`,
                        link: `/${'ward'}/${department}`,
                        width: department.length * 10,
                    }
                })
            // const updatedMenuItems = [...menuItems, ...newTabs]
            const updatedMenuItems = [...newTabs]
            setTabItems(updatedMenuItems)
        }
    }, [data])

    useLayoutEffect(() => {
        updateMenuItems()
    }, [data, isAcceptedTerms])

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        const totalWidthVisible = visibleDepartments.reduce(
            (sum: any, department: any) => sum + department.width,
            0
        )
        const visible = []
        const hidden = []

        const maxWidth = screenWidth - 625

        for (let i = 0; i < tabItems.length; i++) {
            if (i < maxVisibleDepartment && totalWidthVisible <= maxWidth) {
                visible.push(tabItems[i])
            } else {
                hidden.push(tabItems[i])
            }
        }

        setVisibleDepartments(visible)
        setHiddenDepartments(hidden)
    }, [tabItems, screenWidth, maxVisibleDepartment])

    const exists = useMemo(
        () =>
            [...tabItems, ...menuItems].some(
                (item) =>
                    item.link === location.pathname ||
                    '/ScrubChart' ||
                    '/ScrubChartNew'
            ),
        [tabItems]
    )

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const resetStoreOptions = [
        'SortBy',
        'SortOrder',
        'patientNameSearch',
        'hospitalIdSearch',
        'departmenSearch',
        'customFilter',
        'genderOptions',
        'genderFilter',
        'unitsOptions',
        'patientsDataAPI',
        'pageNumber',
        'hasNextPage',
        'isLoading',
    ]

    const reset = (menuItem: string) => {
        dispatch(setActiveDepartment(menuItem))
        dispatch(setPatientsDataAPI([]))
        dispatch(resetFilter(resetStoreOptions))
    }

    return (
        <nav className={styles.menu} ref={componentRef}>
            <NavigationPages reset={reset} menuItems={menuItems} />
            {/* {isWardV2 &&
                visibleDepartments.map((menuItem) => {
                    return (
                        <div
                            className={styles.menu_item}
                            key={menuItem.link.replace('/', '')}
                        >
                            <NavLink
                                to={menuItem.link}
                                className={({
                                    isActive,
                                }: {
                                    isActive: boolean
                                }) => (isActive ? styles.active : '')}
                                onClick={() => reset(menuItem.title)}
                            >
                                {({ isActive }: { isActive: boolean }) =>
                                    isActive ? (
                                        <>
                                            <div className={styles.flag} />
                                            <span>
                                                {t(`${menuItem.title}`)}
                                            </span>
                                        </>
                                    ) : (
                                        <span>{t(`${menuItem.title}`)}</span>
                                    )
                                }
                            </NavLink>
                        </div>
                    )
                })} */}
            {/* {isWardV2 && tabItems?.length > 0 ? ( */}
            {tabItems?.length > 0 ? (
                <NavigationDropdown items={tabItems} reset={reset} />
            ) : null}
        </nav>
    )
}

export default Menu
