import { getUser } from 'utils/Keycloak'
import { RootState } from '..'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { BaseQueryApi } from '@reduxjs/toolkit/query'

export const prepareHeaders = (
    headers: Headers,
    { getState }: Pick<BaseQueryApi, 'getState'>
): Headers => {
    // const token = selectToken(getState() as RootState)
    const userRole = selectActiveUserRole(getState() as RootState)
    const user = getUser()
    const token = user?.access_token
    // // Redirect if no token or role
    // if (!token || !userRole) {
    //     window.location.href = '/login' // Redirect to your login page or another appropriate route
    //     return headers // Return headers to avoid making the request
    // }

    if (token) {
        // headers.set('Access-Control-Allow-Origin', '*')
        headers.set('bearer', `${token}`)
        // headers.set('Authorization', `Bearer ${token}`)
        headers.set(
            'Ocp-Apim-Subscription-Key',
            `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
        )
        headers.set('Role', userRole)
    }
    return headers
}
