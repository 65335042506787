import { UserManager, User, WebStorageStateStore } from 'oidc-client-ts'

const baseKeycloakURL = process.env.REACT_APP_KEYCLOAK_HOST
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM as string
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
const localHost = process.env.REACT_APP_API_HOST
const isLocal = localHost === 'http://localhost:3000/'

const authority = `${baseKeycloakURL}/realms/${keycloakRealm}`

export const oidcConfig = {
    authority: authority,
    client_id: keycloakClientId,
    redirect_uri: `${window.location.origin}/`,
    post_logout_redirect_uri: `${window.location.origin}`,
    response_type: 'code',
    // scope: 'openid profile email',
    automaticSilentRenew: !isLocal,
    silent_redirect_uri: `${window.location.origin}/silent-renew`,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    silentRenewTimeout: 5000,
}

const storageKey = `oidc.user:${authority}:${keycloakClientId}`
export function getUser() {
    const oidcStorage = localStorage.getItem(storageKey)
    if (!oidcStorage) {
        return null
    }

    return User.fromStorageString(oidcStorage)
}

export const injectTokenManually = async (
    token: string,
    userManager: UserManager
) => {
    try {
        // Decode JWT to extract the profile
        const tokenPayload = JSON.parse(atob(token.split('.')[1]))
        console.log('🚀 ~ tokenPayload:', tokenPayload)
        const expiresAt =
            tokenPayload.exp || Math.floor(Date.now() / 1000) + 3600 // Fallback to 1 hour expiration if not provided

        // Create a User instance using the constructor
        const user = new User({
            id_token: token,
            session_state: null,
            access_token: token,
            token_type: 'Bearer',
            scope: 'openid profile email', // Your OIDC scopes
            profile: tokenPayload,
            expires_at: expiresAt,
            userState: null,
        })

        // Store the user in the UserManager's storage
        await userManager.storeUser(user)
        localStorage.setItem(storageKey, user.toStorageString())
        const storedUser = localStorage.getItem(storageKey)
        console.log('Token injected successfully')
    } catch (error) {
        console.error('Failed to inject token:', error)
    }
}
