import { useSelector } from 'react-redux'
import { useAuth } from 'react-oidc-context'
import { selectToken } from 'redux/rtk/auth/selectors'

export const useSkipCondition = () => {
    const userToken = useSelector(selectToken)
    const { isAuthenticated } = useAuth()

    // Returns the skip condition
    return !userToken && !isAuthenticated
}
