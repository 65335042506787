import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { InfectionResponce, WardSectionsResponce } from './types'
import {
    INFECTION_MOCK_DATA,
    WARD_SECTIONS_MOCK_DATA,
    NEW_WARD_SECTIONS_MOCK_DATA,
} from 'utils/__Mocks__/InvectionMock'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'

const unitsBaseURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}/api/v1/units/`

export const unitsApi = createApi({
    reducerPath: 'unitsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: unitsBaseURL,
        prepareHeaders,
    }),

    tagTypes: ['Infections', 'Ward Sections'],

    endpoints: (builder) => ({
        getInfectionControl: builder.query<InfectionResponce, void>({
            // query: () => `infection-control`,
            // providesTags: [{ type: 'Infections', id: 'LIST_INFECTION' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${unitsBaseURL}infection-control`,
                () => INFECTION_MOCK_DATA
            ),
        }),
        getWardSections: builder.query<WardSectionsResponce, void>({
            // query: () => `ward-sections`,
            // providesTags: [{ type: 'Ward Sections', id: 'WARD_SECTIONS' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${unitsBaseURL}ward-sections`,
                // () => WARD_SECTIONS_MOCK_DATA
                () => NEW_WARD_SECTIONS_MOCK_DATA
            ),
        }),
    }),
})

export const { useGetInfectionControlQuery, useGetWardSectionsQuery } = unitsApi
