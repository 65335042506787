import React, { useState } from 'react'
import styles from './FeedbackButton.module.scss'
import { icons } from 'utils/iconsMap'
import { Button, Transition } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { selectLocale } from 'redux/rtk/user/selectors'
import { useSelector } from 'react-redux'

interface FeedbackButtonProps {
    feedbackUrl: string
    text: string
    showIcon?: boolean
    icon?: React.ReactNode
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
    feedbackUrl,
    text,
    showIcon = true,
    icon,
}) => {
    const handleClick = () => {
        window.open(feedbackUrl, '_blank')
    }
    const [isHovered, setIsHovered] = useState(false)
    const locale = useSelector(selectLocale)
    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)
    const { t } = useTranslation()

    return (
        <Button
            variant="transparent"
            // className={styles['feedback-button']}
            onClick={handleClick}
            justify="space-between"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            classNames={{
                root: `${styles.feedback__root}`,
                loader: `${styles.feedback}`,
                inner: `${styles.feedback__inner}`,
                section: `${styles.feedback__section}`,
                label: `${styles.feedback__label}`,
            }}
            styles={{ root: { right: `${locale === 'fr' ? -30 : -40}px` } }}
            leftSection={
                showIcon &&
                (icon ? (
                    icon
                ) : (
                    <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.31299 17.0835L10 17.0835C14.0112 17.0835 16.0168 17.0835 17.2629 15.8997C18.509 14.7159 18.509 12.8106 18.509 9C18.509 5.1894 18.509 3.2841 17.2629 2.1003C16.0168 0.916503 14.0112 0.916504 10 0.916505C5.98887 0.916505 3.98329 0.916504 2.73719 2.1003C1.49108 3.2841 1.49108 5.1894 1.49108 9L1.49108 10.7018"
                            stroke="url(#paint0_linear_12151_3884)"
                            strokeWidth="1.27634"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.31092 15.8526C4.86707 16.2742 4.64514 16.4851 4.39928 16.5859C4.04508 16.7311 3.64349 16.7311 3.28929 16.5859C3.04343 16.4851 2.8215 16.2742 2.37765 15.8526C1.93379 15.4309 1.71187 15.2201 1.60574 14.9865C1.45284 14.65 1.45284 14.2685 1.60574 13.932C1.71187 13.6985 1.93379 13.4876 2.37765 13.066L6.17294 9.46043C7.10801 8.57212 7.57554 8.12796 8.16111 7.86479C8.74668 7.60163 9.40467 7.53995 10.7207 7.41659L11.3164 7.36075L11.2576 7.9267C11.1278 9.17689 11.0628 9.80199 10.7858 10.3583C10.5088 10.9146 10.0413 11.3587 9.10622 12.247L5.31092 15.8526Z"
                            stroke="url(#paint1_linear_12151_3884)"
                            strokeWidth="1.27634"
                            strokeLinejoin="round"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_12151_3884"
                                x1="18.509"
                                y1="8.93922"
                                x2="2.28153"
                                y2="5.35776"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#1970B9" />
                                <stop offset="1" stopColor="#0987B1" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_12151_3884"
                                x1="11.3164"
                                y1="11.9927"
                                x2="1.94743"
                                y2="9.92493"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#1970B9" />
                                <stop offset="1" stopColor="#0987B1" />
                            </linearGradient>
                        </defs>
                    </svg>
                ))
            }
        >
            {t(text)}
            {/* <Transition
                mounted={isHovered}
                transition="fade-left"
                duration={400}
                timingFunction="ease"
            >
                {(transitionStyle) => (
                    <div style={transitionStyle}>
                        {isHovered && (
                            <span className={styles['feedback-text']}>
                                {t(text)}
                            </span>
                        )}
                    </div>
                )}
            </Transition> */}
        </Button>
    )
}

export default FeedbackButton
