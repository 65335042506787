import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import { AuthProvider } from 'react-oidc-context'
import storeRtk, { persistor } from './redux/rtk'
import { MantineProvider } from '@mantine/core'
import router from 'routes/Routes'

import 'App.module.scss'
import { oidcConfig } from 'utils/Keycloak'

function App() {
    return (
        <AuthProvider {...oidcConfig}>
            <Provider store={storeRtk}>
                <MantineProvider defaultColorScheme="dark">
                    <PersistGate loading={null} persistor={persistor}>
                        <RouterProvider router={router} />
                    </PersistGate>
                </MantineProvider>
            </Provider>
        </AuthProvider>
    )
}

export default App
