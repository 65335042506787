import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { NEW_MSSS_NSA_MOCK_DATA } from '../../../utils/__Mocks__/MsssNsaNewMockData'
import { MSSS_NSA_PREDICTION_MOCK } from '../../../utils/__Mocks__/MsssNsaPredictionMock'
import {
    NEW_MSSS_NSA_MOCK,
    NSA_CATEGORIES_MOCK,
} from '../../../utils/__Mocks__/newMsssNsaMock'
import { NSA_DEPARTMENT_MOCK_DATA } from '../../../utils/__Mocks__/NsaDepartmentMock'
import {
    WaitingData,
    CategoryData,
    DataStructure,
    Category,
    NsaCategoriesList,
    NsaOperationalResponce,
    EmergencyDepartmentObject,
} from './types'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import { ED_DEMOGRAPHICS_MOCK } from 'utils/__Mocks__/EmergencyDepartmentMockData'

const baseURL = process.env.REACT_APP_SCRUBCHART_API_URL

const nsaEdDemographicsURL = `${baseURL}/api/NsaEdDemographics`

export const nsaApi = createApi({
    reducerPath: 'nasApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: nsaEdDemographicsURL,
        prepareHeaders,
    }),

    tagTypes: ['Categories', 'Departments', 'NsaInfections'],
    endpoints: (builder) => ({
        // getNsaCategories: builder.query<CategoryData, any>({
        //     query: () => `v1/NSA/categories`,
        //     providesTags: [{ type: 'Categories', id: 'LIST_CATEGORIES' }],
        //     transformResponse: (response: CategoryData): CategoryData => {
        //         if (isDevelopment) {
        //             return NEW_MSSS_NSA_MOCK
        //         }
        //         return response
        //     },
        // }),
        getNsaCategories: builder.query<NsaCategoriesList, any>({
            // query: () => `/nsa-categories`,
            // providesTags: [{ type: 'Categories', id: 'LIST_CATEGORIES' }],
            // transformResponse: (
            //     response: NsaCategoriesList
            // ): NsaCategoriesList => {
            //     if (isDevelopment) {
            //         return NSA_CATEGORIES_MOCK
            //     }
            //     return response
            // },
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${nsaEdDemographicsURL}/nsa-categories`,
                () => NSA_CATEGORIES_MOCK
            ),
        }),
        getNsaDepartments: builder.query<NsaOperationalResponce, any>({
            // query: () => `/nsa-operational`,
            // providesTags: [{ type: 'Departments', id: 'LIST_Departments' }],
            // transformResponse: (
            //     response: NsaOperationalResponce
            // ): NsaOperationalResponce => {
            //     if (isDevelopment) {
            //         return NSA_DEPARTMENT_MOCK_DATA
            //     }
            //     return response
            // },
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${nsaEdDemographicsURL}/nsa-operational`,
                () => NSA_DEPARTMENT_MOCK_DATA
            ),
        }),
        getNsaCategoriesPrediction: builder.query<any, any>({
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${baseURL}/api/v1/predictions/NsaPredictions`,
                () => MSSS_NSA_PREDICTION_MOCK
            ),
        }),
        getNsaEdDemographics: builder.query<EmergencyDepartmentObject, any>({
            // query: () => `/ed-demographics`,
            // providesTags: [{ type: 'EmergencyDepartment', id: 'LIST_ED' }],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${nsaEdDemographicsURL}/ed-demographics`,
                () => ED_DEMOGRAPHICS_MOCK
            ),
        }),
    }),
})

export const {
    useGetNsaCategoriesQuery,
    useGetNsaDepartmentsQuery,
    useGetNsaCategoriesPredictionQuery,
    useGetNsaEdDemographicsQuery,
} = nsaApi
