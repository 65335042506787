import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { labsEndBaseUrl } from '../../../utils/API'
import { ED_DEMOGRAPHICS_MOCK } from '../../../utils/__Mocks__/EmergencyDepartmentMockData'
import { EmergencyDepartmentObject } from './types'
import { prepareHeaders } from '../helpers/prepareHeaders'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT === 'Development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development'

export const labsEndApi = createApi({
    reducerPath: 'labsEndApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: labsEndBaseUrl,
        prepareHeaders,
    }),

    tagTypes: ['EmergencyDepartment'],
    endpoints: (builder) => ({
        getEmergencyDepartment: builder.query<EmergencyDepartmentObject, any>({
            query: () => `v1/EmergencyDepartment`,
            providesTags: [{ type: 'EmergencyDepartment', id: 'LIST_ED' }],
            transformResponse: (
                response: EmergencyDepartmentObject
            ): EmergencyDepartmentObject => {
                if (isDevelopment) {
                    // return EmergencyDepartmentDataMock
                    return ED_DEMOGRAPHICS_MOCK
                }
                return response
            },
        }),
    }),
})

export const { useGetEmergencyDepartmentQuery } = labsEndApi
