import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLogPageVisitMutation } from 'redux/rtk/corporateView/corporateViewApi'
import { selectUserDetails } from 'redux/rtk/user/selectors'

export const usePageVisitLogger = () => {
    const location = useLocation()
    const userDetails = useSelector(selectUserDetails)
    const [logPageVisit] = useLogPageVisitMutation()

    useEffect(() => {
        const logVisit = async (pageUrl: string, userName: string) => {
            try {
                await logPageVisit({
                    visitedPage: pageUrl,
                    userName: userName,
                }).unwrap()
                console.log(`Page visit logged: ${pageUrl}`)
            } catch (error) {
                console.error('Error logging page visit:', error)
            }
        }

        // Log the current page visit when the location changes
        logVisit(location.pathname, userDetails.userName)
    }, [location, logPageVisit])
}
