import axios, { InternalAxiosRequestConfig, AxiosHeaders } from 'axios'
import { getUser } from './Keycloak'

export const OcpApimSubscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY

const headers = {
    'Ocp-Apim-Subscription-Key': `${OcpApimSubscriptionKey}`,
}

// New interceptor function to handle token setting
export function handleRequestConfig(
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
    const user = getUser()
    const token = user?.access_token

    // Ensure headers are of type AxiosHeaders
    const headers =
        config.headers instanceof AxiosHeaders
            ? config.headers
            : new AxiosHeaders(config.headers)

    // Set the Authorization header
    if (token) {
        headers.set('bearer', `${token}`)
        // headers.set('Authorization', `Bearer ${token}`)
    }

    // Assign the updated headers back to the config
    config.headers = headers

    return config
}

// Create Axios instance with the new interceptor function
export const createAxiosInstance = (
    baseURL: string,
    responseType: 'json' | 'blob'
) => {
    const instance = axios.create({
        baseURL,
        withCredentials: true,
        responseType,
        headers,
    })

    instance.interceptors.request.use(handleRequestConfig)

    return instance
}
