import React, { Fragment, useEffect } from 'react'
import styles from './Login.module.scss'
import { baseURL } from '../../utils/API'
import Iframe from 'react-iframe'
import { useAppDispatch } from '../../redux/rtk'
import { setKeycloakToken } from '../../redux/rtk/auth/authSlice'
import { useAuth } from 'react-oidc-context'

const Login = () => {
    const { user, isAuthenticated } = useAuth() // Destructure useful props from useAuth
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isAuthenticated && user) {
            dispatch(setKeycloakToken(user.access_token)) // Dispatch the token to Redux store
        }
    }, [isAuthenticated, user, dispatch])

    // If the user is not authenticated, return an empty fragment or a loading spinner
    if (!isAuthenticated) {
        return <Fragment>Loading...</Fragment> // Or your custom loading state
    }

    return (
        <Fragment>
            {/* Only render the iframe after successful authentication */}
            <Iframe
                className={styles.login_wrap}
                // ${baseURL}/
                url={`${user?.profile?.login_url}`} // Use the URL from user profile or your desired URL
                width="100%"
                height="100vh"
            />
        </Fragment>
    )
}

export default Login
