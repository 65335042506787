import React, { ReactElement, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'components/Header/Header'
import { useAppDispatch } from 'redux/rtk'
import { useSelector } from 'react-redux'
import { useKeycloak } from '@react-keycloak/web'
import { getAuthData, setKeycloakToken } from 'redux/rtk/auth/authSlice'
import { ToastContainer, toast } from 'react-toastify'
import { selectTheme } from 'redux/rtk/user/selectors'
import FeedbackButton from 'components/common/FeedbackButton/FeedbackButton'

import 'react-toastify/dist/ReactToastify.css'
import styles from './Layout.module.scss'
import { useAuth } from 'react-oidc-context'
import { injectTokenManually, oidcConfig } from 'utils/Keycloak'
import { UserManager } from 'oidc-client-ts'
import { usePageVisitLogger } from 'hooks'

const Layout = (): ReactElement => {
    const dispatch = useAppDispatch()
    // const { initialized, keycloak } = useKeycloak()
    const theme = useSelector(selectTheme)
    const { isAuthenticated, user } = useAuth()
    const auth = useAuth()
    // const scale = window.devicePixelRatio
    // console.log(`Device scale factor: ${scale}`)
    // console.log(
    //     `Screen resolution: ${window.screen.width}x${window.screen.height}`
    // )
    // console.log(
    //     `Browser window resolution: ${window.innerWidth}x${window.innerHeight}`
    // )
    usePageVisitLogger()

    // const isLocalEnvironment =
    //     process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'development' &&
    //     process.env.REACT_APP_API_HOST === 'http://localhost:3000/'
    // useEffect(() => {
    //     // If the user is not authenticated and we are in development, inject the token
    //     if (isLocalEnvironment) {
    //         const productionToken = process.env.REACT_APP_TEST_TOKEN
    //         if (productionToken) {
    //             const userManager = new UserManager(oidcConfig)
    //             injectTokenManually(productionToken, userManager)
    //         }
    //     }
    // }, [user])

    useEffect(() => {
        if (isAuthenticated && user) {
            // Safely access name with optional chaining and fallback
            const name =
                user.profile?.name ?? // Check for name
                (user.profile?.given_name && user.profile?.family_name
                    ? `${user.profile?.given_name} ${user.profile?.family_name}`
                    : user.profile?.preferred_username ?? 'Unknown') // Fallback to preferred_username or 'Unknown'

            dispatch(
                getAuthData({
                    userName: name,
                    userRole: user.profile?.roles || [], // Safely access roles
                    authorized: isAuthenticated,
                    userToken: user.access_token,
                })
            )
            dispatch(setKeycloakToken(user.access_token))
        }
    }, [isAuthenticated, user, dispatch])

    useEffect(() => {
        if (isAuthenticated && user) {
            // Safely access name with optional chaining and fallback
            const name =
                user.profile?.name ?? // Check for name
                (user.profile?.given_name && user.profile?.family_name
                    ? `${user.profile?.given_name} ${user.profile?.family_name}`
                    : user.profile?.preferred_username ?? 'Unknown') // Fallback to preferred_username or 'Unknown'

            dispatch(
                getAuthData({
                    userName: name,
                    userRole: user.profile?.roles || [], // Safely access roles
                    authorized: isAuthenticated,
                    userToken: user.access_token,
                })
            )
        }
    }, [isAuthenticated, user, dispatch])

    return (
        <div className={`${styles.layout} ${styles[theme + '-theme']}`}>
            <Header />
            <main className={styles.layout__main}>
                <Outlet /> {/* This will render the nested routes */}
            </main>
            <ToastContainer />
            <FeedbackButton
                text="Feedback"
                feedbackUrl="https://forms.office.com/r/qaP05geHQE"
            />
        </div>
    )
}

export default Layout
