import React from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Link } from 'react-router-dom'
import NotFound from 'pages/NotFound/NotFound'

import styles from './ErrorPage.module.scss'

const ErrorPage: React.FC = () => {
    // const error = useRouteError() as { status?: number; message?: string }
    const error = useRouteError() as any

    if (error.status === 404) {
        return <NotFound />
    }

    const getErrorMessage = () => {
        let errorMessage: string

        if (isRouteErrorResponse(error)) {
            // error is type `ErrorResponse`
            errorMessage = error.error?.message || error.statusText
        } else if (error instanceof Error) {
            errorMessage = error.message
        } else if (typeof error === 'string') {
            errorMessage = error
        } else {
            console.error(error)
            errorMessage = 'Something went wrong. Please try again later.'
        }
        return errorMessage
    }

    return (
        <div className="error-page">
            <h1>Oops! {error.status || 500}</h1>
            <p>{getErrorMessage()}</p>
            <Link to="/">Go back to Home</Link>
        </div>
    )
}

export default ErrorPage
