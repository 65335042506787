import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import {
    CriticalCareResponce,
    OperaAdmissionsResponce,
    OperaRoomsResponce,
    UnitAnalyticsData,
    unitGroupsResponse,
} from './types'
import {
    CRITICAL_CARE_MOCK,
    OPERA_ADMISSIONS_MOCK,
    OPERA_ROOMS_MOCK,
    OPERA_TRANSFER_MOCK,
} from 'utils/__Mocks__/CorporateViewMock'
import {
    ANALITICS_MOCK_DATA,
    UNIT_GROUPS_MOCK,
} from 'utils/__Mocks__/NewCorporateMocks'

const corporateViewBaseURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}`

export const corporateViewApi = createApi({
    reducerPath: 'corporateViewApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: corporateViewBaseURL,
        prepareHeaders,
    }),

    tagTypes: [
        'criticalCare',
        'operaRooms',
        'admissions',
        'transfers',
        'unitAnalytics',
        'unitGroups',
    ],

    endpoints: (builder) => ({
        getCriticalCare: builder.query<CriticalCareResponce, void>({
            // query: () => `/critical-care`,
            // providesTags: [{ type: 'criticalCare', id: 'CRITICAL_CARE' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${corporateViewBaseURL}/critical-care`,
                () => CRITICAL_CARE_MOCK
            ),
        }),
        getOperaRooms: builder.query<OperaRoomsResponce, void>({
            // query: () => `/opera/rooms`,
            // providesTags: [{ type: 'operaRooms', id: 'OPERA_ROOMS' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${corporateViewBaseURL}/opera/rooms`,
                () => OPERA_ROOMS_MOCK
            ),
        }),
        getOperaAdmissions: builder.query<OperaAdmissionsResponce, void>({
            // query: () => `/opera/pending-admissions`,
            // providesTags: [{ type: 'admissions', id: 'ADMISSIONS' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${corporateViewBaseURL}/opera/pending-admissions`,
                () => OPERA_ADMISSIONS_MOCK
            ),
        }),
        getOperaTransfers: builder.query<OperaAdmissionsResponce, void>({
            // query: () => `/opera/pending-transfers`,
            // providesTags: [{ type: 'transfers', id: 'TRANSFER' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${corporateViewBaseURL}/opera/pending-transfers`,
                () => OPERA_TRANSFER_MOCK
            ),
        }),
        logPageVisit: builder.mutation<
            void,
            { visitedPage: string; userName: string }
        >({
            query: ({ visitedPage, userName }) => ({
                url: `/api/page-visit-log/add`,
                method: 'POST',
                body: { visitedPage, userName },
            }),
        }),
        getUnitsAnalyticsData: builder.query<UnitAnalyticsData[], void>({
            // query: () => `/api/v1/units/census-unit-groups-demographics'`,
            // providesTags: [{ type: 'unitAnalytics', id: 'unitAnalytics' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () =>
                    `${corporateViewBaseURL}/api/v1/units/census-unit-groups-demographics`,
                () => ANALITICS_MOCK_DATA
            ),
        }),
        getUnitGroups: builder.query<unitGroupsResponse, void>({
            // query: () => `/api/v1/units/census-unit-groups`,
            // providesTags: [{ type: 'unitGroups', id: 'unitGroups' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${corporateViewBaseURL}/api/v1/units/census-unit-groups`,
                () => UNIT_GROUPS_MOCK
            ),
        }),
    }),
})

export const {
    useGetCriticalCareQuery,
    useGetOperaAdmissionsQuery,
    useGetOperaRoomsQuery,
    useGetOperaTransfersQuery,
    useLogPageVisitMutation,
    useGetUnitsAnalyticsDataQuery,
    useGetUnitGroupsQuery,
} = corporateViewApi
