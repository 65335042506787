import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { backendApiUrl } from 'utils/API'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import { PatientDetails, PatientDetailsResponse } from './types'
import { MOCK_PATIENT_VIEW } from 'utils/__Mocks__/PatientViewMock'

export const patientViewApi = createApi({
    reducerPath: 'patientViewApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendApiUrl}/api/PatientView/`,
        prepareHeaders,
    }),

    tagTypes: ['PatientView'],

    endpoints: (builder) => ({
        getPatientView: builder.query<PatientDetailsResponse, string>({
            providesTags: (result, error, patientId) => [
                { type: 'PatientView', id: `VISIT_${patientId}` },
            ],
            //@ts-ignore
            queryFn: createQueryFn(
                (patientId: string) => {
                    return `${backendApiUrl}/api/PatientView/patients/${patientId}/patientview`
                },
                () => MOCK_PATIENT_VIEW
            ),
        }),
    }),
})

export const { useGetPatientViewQuery } = patientViewApi
