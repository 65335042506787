import { PatientDetails } from 'redux/rtk/PatientView/types'

export const MOCK_PATIENT_VIEW = {
    patientDetails: {
        patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
        mrn: '1420792',
        firstName: 'OJARS',
        lastName: 'RAMKA',
        visitDiagnosis: 'thrombocytopenia',
        visitLengthOfStaySinceAdmissionHours: 2150,
        visitFollowingDoctorName: 'GILLMAN MARCIA',
        visitIsolationStatus: null,
        locationServiceName: null,
        locationUnitName: '4M',
        locationRoomName: '414',
        locationBedName: 'A',
        patientGender: 'MALE',
        patientAge: 71,
        communityCareInstitution: null,
        communityIntervenantPivotName: null,
        communityProgram: null,
        communityLastInterventionDatetime: null,
        communityInterventionProgram: null,
        patientVisits: [
            {
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                lengthOfStayServiceHours: 114,
                serviceName: null,
                visitStartDatetime: '2024-11-29T13:14:00',
                visitEndDatetime: null,
            },
            {
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                lengthOfStayServiceHours: 1613,
                serviceName: null,
                visitStartDatetime: '2024-09-23T07:50:00',
                visitEndDatetime: '2024-11-29T13:14:00',
            },
        ],
        patientSurgeries: [],
        patientResults: [
            {
                uniqueId: '0069340e117b96b67e617246d226aa09',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'Fungal Deep Mycosis Culture',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0193ca76ad579179d37000390e609cca',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Immature Granulocytes',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '01f2ffbe08312273a4eafaa51449680c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'Nrbc#',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '023a8840a2097e4d11df5727eae86cc5',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CBC:HEMOG',
                resultDescription: 'MCV',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:22:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '026b5ee89e587ac0775904012d18a145',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALB',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0367b2ce12e4eb6c2f0e01d1158e9da3',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:LDH',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '038b2186b9c1fd05a82d1bdc2159b465',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CK',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '03a577d06b8301cc3664582cf8184465',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:ALT',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '047c1b596c5f1f622d9a2045be7a512f',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CA',
                resultDescription: 'Albumin',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '05128e30dae3e45b72b7f718480b6912',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Urea',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '05ca3515200afdecc59861dbdf76a52a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-15T00:00:00+00:00',
                resultTimestamp: '2024-11-15T14:48:35+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '0643c6127725fcb6b3c179282f4eede3',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Others',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0693701f63705129d8d2da5063e576d4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC',
                resultDescription: 'MDIFF to be done (WAM)',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '06fc9f2bc5f1d138863aef2d3f051f3f',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:URIC',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '06ffa7117eb8bff7719e7fdc6cd45c33',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:ALT',
                resultDescription: 'ALT',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0948b5e7ba6c9414d2ebd2a8543f8950',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CHEM7',
                resultDescription: 'Urea',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '09c219f610da2feb50b94d3bdf527013',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT SCAN UPPER EXTREMITIES C-',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-21T00:00:00+00:00',
                resultTimestamp: '2024-11-21T10:43:23+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '09eed1db1c49a2dcc05057b476a482ab',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Eosinophils',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0a7ac58a21ca41987781481339c63a13',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-22T00:00:00+00:00',
                resultTimestamp: '2024-10-22T21:42:18+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '0ac5efaf2f4e002016062f7cd4a02af2',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:TP',
                resultDescription: 'Total Protein',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0b895ddf90e5181264542ed7ad876ddd',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3220592M:BCA',
                resultDescription: 'Aerobic Blood Culture Bottle',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-22T00:00:00+00:00',
                resultTimestamp: '2024-11-27T12:32:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0bfe52c8f1970ec356fcd9c57dfcde60',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'ECHO SURFACE - OTHER MISCELLANEOUS REGION',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-19T00:00:00+00:00',
                resultTimestamp: '2024-11-19T16:03:25+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '0c226e363d17fc5aa6b6ed5a17b3ff23',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'RDW-SD',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0c30122556ce0c82377f6fcdbe784aa4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:MG',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0cd06cd33dfb842b61aa483fa790763b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3283835M:CARS1',
                resultDescription:
                    'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-30T13:07:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0d3376ef5a9f6142b7ced81457056525',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:GGT',
                resultDescription: 'GGT',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0d766eae42955cd494a08fa5f8a0db44',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT SCAN ABDOMEN-PELVIS C+',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-22T00:00:00+00:00',
                resultTimestamp: '2024-10-22T21:42:18+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '0dd620ddd0bb705c264d56ee980773f1',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:PHO',
                resultDescription: 'Phosphorus',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '0e25f31d4e173e3c7b3819495590936c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:MG',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '11c6078b62f123036d61e41c558f33a5',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:URIC',
                resultDescription: 'Uric Acid',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '11e5d708cf8ec6ae3a13aff1a88bf288',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:GGT',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '11eb28edf7eca6900e7d3e606067874a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CBC:HEMOG',
                resultDescription: 'RBC',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:22:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '127ffb28d7d9e47cb9997a319c7564e3',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'RDW-CV',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '12ea34548efcde6fd81d0719bc4971d7',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W2230454:TRYPT',
                resultDescription: 'Approved by:',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-23T00:00:00+00:00',
                resultTimestamp: '2024-11-30T15:14:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '14117a349dea4d08794fbd7aea89bf04',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CA',
                resultDescription: 'Albumin',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '144bd3738f15474f15a9211f30617943',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'KOH Preparation',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '164db914d6fce63b2a148620a26211ab',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:TP',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '16502225aa011daac5e9cdd0c62c2727',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:BILT',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '168d2fc0327919fd8419a0cbf24dfe00',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-03T00:00:00+00:00',
                resultTimestamp: '2024-11-03T13:45:41+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '174210d9452e0bb5282a32cc43e152db',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'PLAT',
                resultFlags: 'Very low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '184a1f581de7cf889ae2a672e87754a5',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Giant Platelets',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '1899a25561152f672a814124bf028d6b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W2230454:TRYPT',
                resultDescription: 'Analysis performed at:',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-23T00:00:00+00:00',
                resultTimestamp: '2024-11-30T15:14:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '18c162b42d139f8de24c16ed1160efc4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CA',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '18dc9b1839da826a524745eab3cf7547',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALK',
                resultDescription: 'Alk. Phos.',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '18feb8f248376aa3dd71a1ca1268089b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '195b329b3220becd88eb722481df3f97',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:ALT',
                resultDescription: 'ALT',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '1a3d6535c2d3dbd2583f285f53336e2c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CA',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '1a4bac44903e858aa95a8340edac352a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3220642M:BCAN',
                resultDescription: 'Anaerobic Blood Culture Bottle',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-22T00:00:00+00:00',
                resultTimestamp: '2024-11-27T12:37:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '1aa80c839a066a31cd902abe785b6264',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CK',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '1cbb7f62a3c62be5aa4926dfdadf785b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:MG',
                resultDescription: 'Magnesium',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2013f66904ca1d1a68232fab06a638df',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CHEM7',
                resultDescription: 'Glucose-downloaded-Interface',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '215ebcd71495dca6eecc0a0e0dccbabd',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALK',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '220b806e5db835a323fde6600d57ebd6',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Glucose-downloaded-Interface',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '22fee9241cee5adb3f2330ccb104dce1',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:LDH',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '231941036fa125c6eedfad8295049b89',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CA',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '235b34de6b81fba5af82851baf9f54fe',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT-IMAGE MANIPULATION (per 5 mins.-max 6)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-21T00:00:00+00:00',
                resultTimestamp: '2024-11-21T10:43:23+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '237f57467712000ce66581bcb9916156',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Small Platelets',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '238818d6337749fefb2e383ee9a88647',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'Fungal Deep Mycosis Culture',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '256c2245d55053bd6242e92c1cb4d205',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CA',
                resultDescription: 'Albumin',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '25e47ef1adea74a12b82e563d1a222c4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:MG',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2678a8acf962c9bc24bfdb42864080f9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'Nrbc%',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '26b6abc8d8aac439a4b30d2b44efaa17',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CA',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '26fbbb3b72f7b74a0698769dcf205801',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CHEM7',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2783fda8707404d99d45e8a6a1aca734',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:TP',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2784db6ead83ece9e067b71387e406eb',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'MCH',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '27a70040171e180637da36c9ec0003f6',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:TP',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '28347676755652d3f3fdcac3522c9c00',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:PHO',
                resultDescription: 'Phosphorus',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2948ba59b4393d4549ec28d36838af34',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT SCAN THORAX C+ (inc. suprarenals)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-22T00:00:00+00:00',
                resultTimestamp: '2024-10-22T21:42:18+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '29fe2e821a0d62609eb0b57ed3bc91cf',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Sodium',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2aeeccc537d99088d9e30d8f28b8528a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3283814M:VREPC',
                resultDescription: 'VRE (Nucleic Acid Detection)',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-29T13:27:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2bd8228401f72616a284329ee1e74866',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:ALB',
                resultDescription: 'Albumin',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2cae972e5393620767909bff200805f7',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALB',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2d112c42e2467d4824e777650811234e',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:BILT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2d4e50f761a7552ecdf74813d2bdc174',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CHEM7',
                resultDescription: 'Chloride',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '2f6bd09794f1b0b9fdfb2ba162ea0a5f',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W2230454:TRYPT',
                resultDescription: 'Approved by:',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-23T00:00:00+00:00',
                resultTimestamp: '2024-11-30T15:14:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '31b943b9a020b1815bde0c0a8fd2d48d',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CA',
                resultDescription: 'Corrected Calcium',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '332bfa233762e1e91177a543df2ffb8d',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:ALT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '33c5d053755d55b4557d976d613d9f2f',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'Fungal Deep Mycosis Culture',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '347de121ea4c743dce75f05e240249fe',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:TP',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '34ef1aad9236f6b9b40c0f43f78eba96',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3262347M:BCA',
                resultDescription: 'Aerobic Blood Culture Bottle',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-26T00:00:00+00:00',
                resultTimestamp: '2024-12-01T15:16:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '351e931b2bb1364955a63aa7ee05488c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CBC:HEMOG',
                resultDescription: 'MCH',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:22:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '35d5858b2ba3094401265cf2c6a8f384',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CHEM7',
                resultDescription: 'Glucose',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '3601661997cd64cff7416930d8499ff9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:PHO',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '37e91f3ab4282b36f71c983eab48e744',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT SCAN THORAX C+ (inc. suprarenals)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-25T00:00:00+00:00',
                resultTimestamp: '2024-11-25T14:58:44+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '37faf4a6e6c75bba6b0ca676413679c4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:ALK',
                resultDescription: 'Alk. Phos.',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '383476d543da8348eb34121d8cb762a1',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:URIC',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '387ddc6799b3b53cca6a5effa9c34d01',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'MCH',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '3956e6fe8d70d6dcbb49031b97a13457',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:LDH',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '3dcc9be2bd5c2ca661ad7146fffb1211',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'PLAT',
                resultFlags: 'Very low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '3f2878d7e8f7685782d1ae3e2e643cb9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:URIC',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '3fa9f764ef23ef3d0fa3dfb77286ca66',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'WBC',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '43306c6f481db0082e644af266bcf4f2',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CA',
                resultDescription: 'Calcium',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '44094537431840f812030c629793cfa5',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'Fungal Deep Mycosis Culture',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4478c1a80c38811e169d55c7050dec2c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:TP',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '47f1dcd8c8d4e06baaa436253ab10596',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Glucose',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '48337fa0c027e3a7b29c95722c9f7918',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CHEM7',
                resultDescription: 'Bicarbonate',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4838dbe1e239b3bffb6cffdd8212787a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:BILT',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4860cfd53cccb665eec5c5d783ff4967',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CA',
                resultDescription: 'Calcium',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4a92f83886feaffb64a1414044397fa9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CHEM7',
                resultDescription: 'Glucose',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4acd8c631a06a6a607c53166110844e4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:BILT',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4b480213b53b08fe430fd1140f5e27c6',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'Nrbc#',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4bbef6ce952c65713182a2d8c20582fe',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Lymphocytes',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4e8b9e4d788f6fb29f5b8a577b26cfd2',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:MG',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4edb3af87db12625b9b632d5dc58012c',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:BILT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '4ee0a7cadea0877bd7e2636dc8ab2e41',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:GGT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '509cd3e70d0064e4392ea4615560999e',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CHEM7',
                resultDescription: 'Glucose-downloaded-Interface',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '52702179fdf0084478544e64319fc666',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:GGT',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '52a2692492204759bbe86fb6c3bece06',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Others#',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '53807a8d2dd0b25572e83e162fb85803',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:MG',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '53b980a2b4fbf0149e48be18f82d63a9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: '??',
                resultDescription: 'CT SCAN THORAX C- (inc. suprarenals)',
                resultFlags: '??',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-15T00:00:00+00:00',
                resultTimestamp: '2024-11-15T14:48:35+00:00',
                resultCategory: 'IMAGES',
            },
            {
                uniqueId: '556927f50867ad36244ad02a7f45f5f4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CA',
                resultDescription: 'Corrected Calcium',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '55b52b774d59ce89f6675bb5817fa3f6',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:LDH',
                resultDescription: 'LDH',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '55ef6953d796ff85470a6aeb0f58ec29',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3283814M:VREPC',
                resultDescription: 'VRE (Nucleic Acid Detection)',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-29T13:27:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '564386b1bc25c2a1e133f9c1b55a6088',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:LDH',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5730456ee2fcc9b1f77372f859c01d33',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CA',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5746f1a45946a8b39f56a4f75491ba42',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:LDH',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '57b97f2f7dfce4e0d302b5990756971d',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:ALB',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '585bd308ef6f42bdcfcadc46bd7c6755',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:BILT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '58ea78e0e1d6f12c0c70f8f14035e4f1',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CK',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5ac4c28231e77e07d3e152bc89eae822',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3202354M:FUD',
                resultDescription: 'Fungal Deep Mycosis Culture',
                resultFlags: '',
                resultStatus: 'Pending',
                requestTimestamp: '2024-11-20T00:00:00+00:00',
                resultTimestamp: '2024-11-29T09:54:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5ad8005d98731d30cf7464c85b63e888',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CBC:HEMOG',
                resultDescription: 'HGB',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:22:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5c65df2a390aec5ae907ba8e5eff041a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3262356M:BCAN',
                resultDescription: 'Anaerobic Blood Culture Bottle',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-26T00:00:00+00:00',
                resultTimestamp: '2024-12-01T15:19:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5cc5f196111e55fc1f8349bc9d2dfab4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:PHO',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5d8efc495c18d457db9d22b80b21014d',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:HEMOG',
                resultDescription: 'RBC',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5dbe801fd124c8945f9982b91b71a9d4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:URIC',
                resultDescription: 'Uric Acid',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5e3335e23758cee87b6ab2b623b6f890',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC',
                resultDescription: ' ',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5f60dd5505712ff7abccecd192b938a9',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'RDW-SD',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '5fe9d2dc210dae5f728c246487e89c25',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W2230454:TRYPT',
                resultDescription: 'Patient RAMQ',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-10-23T00:00:00+00:00',
                resultTimestamp: '2024-11-30T15:14:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6075364a4432072d2a6f29382d73531f',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:URIC',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '60da3b7f96982bea1bbad8a4f8cd4c85',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALK',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '60e030dbe4352d19798840408c2fefad',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:BILT',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '63460154ec62c249c72e5a2d985c43e2',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:URIC',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '634c4e34cf03fcbe11c6802fc93b405b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:BILT',
                resultDescription: 'Icteric',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '644418de2e069ff056a1a2ed44c517fc',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3283835M:CARS1',
                resultDescription:
                    'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-30T13:07:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '64effd70cd18f944a775012b5f99819a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CHEM7',
                resultDescription: 'Bicarbonate',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:44:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '65690da2bd26dbf55fb02b29e606bef0',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Neutrophils',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '65fe9103a67bc045255f4345dd0215ad',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:TP',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '664d6db117a8e397355fbcb6d632a86e',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'MCV',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6b7d3dae60de5a734b4fe6530dc9ad9b',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:BILT',
                resultDescription: 'Bilirubin Total',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6cb20280a8fd6d8a6293a7965cd1b1e4',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:ALB',
                resultDescription: 'Albumin',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6cd7d4559cc87e853aebce2e17fb4ae6',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:CBC:HEMOG',
                resultDescription: 'HGB',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T06:59:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6dba9df06b13f2e442a8807faaed2b50',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:BILT',
                resultDescription: 'Bilirubin Total',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6dd36e09a4361ca64487aebfd7d7ad55',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:URIC',
                resultDescription: 'Uric Acid',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6fb472ee8b58d3ca0589c45d450bc022',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALT',
                resultDescription: 'ALT',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '6fb63ca849a4f4145678e4e5f1f41eb5',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CHEM7',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '70bac7243cba4f98b69a0e055e6baeb3',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:LDH',
                resultDescription: 'LDH',
                resultFlags: 'High',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:42:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '710d338b06e9b6c007a5c84f35cbdc84',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:MG',
                resultDescription: 'Magnesium',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '72eac774240a3b2ec036b0de77605ca7',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:ALT',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '7468763a0b1ebe845945532883070211',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:CBC:ADIFF',
                resultDescription: 'Lymphocytosis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:10:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '751cdb443d3aaa8d028fb4fac4dad3cb',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:TP',
                resultDescription: 'Lipemia',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '756d12944bc3d88eece4936f701c64c7',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:PHO',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '79ab71f1f6beca8ba9b38c80fdd0d64a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3280661:CHEMH:CBC:HEMOG',
                resultDescription: 'HCT',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-28T00:00:00+00:00',
                resultTimestamp: '2024-11-28T07:22:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '79bdc1f743e53d8c7d2ebe4e21a0303a',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:URIC',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '7ad994185ac4fa63f0f63974b67e2884',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3290533:CHEMH:GGT',
                resultDescription: 'Hemolysis',
                resultFlags: '',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-29T00:00:00+00:00',
                resultTimestamp: '2024-11-29T06:41:00+00:00',
                resultCategory: 'LABS',
            },
            {
                uniqueId: '7e230daa9849f8f63d9d141681a332bf',
                patientUuid: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
                resultType: 'W3270557:CHEMH:TP',
                resultDescription: 'Total Protein',
                resultFlags: 'Low',
                resultStatus: 'Done',
                requestTimestamp: '2024-11-27T00:00:00+00:00',
                resultTimestamp: '2024-11-27T07:28:00+00:00',
                resultCategory: 'LABS',
            },
        ],
        lastModifiedDate: '2024-12-04T06:57:14.931116',
    },
}

export const PATIENT_DETAILES_MOCK = {
    patientDetails: {
        patientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        proceduresDone: 'string',
        surgicalComplications: 'string',
        correctionToMd: 'string',
        dischargePlan: 'string',
        objectivesAndConcerns: 'string',
        otherVentilationAndIvAccess: 'string',
        rhythm: 'string',
        drainsDSGSInteg: 'string',
        pastMedicalHistory: 'string',
        autonomyAndMobility: 'string',
        nutritionAndHydration: 'string',
        eliminationAndSkin: 'string',
        sleep: 'string',
        mobilityPlan: 'string',
        helpProgramInvolved: true,
        professionalsInvolved: 'string',
        caregiverCapacity: 'string',
        homeScreening: 'string',
        dsieStatus: 'string',
        prescriptionMedication: 'string',
        allergiesAndIntolerances: 'string',
        other: 'string',
        birthWeight: 0,
        gestationalAgeAtBirth: 0,
        correctedGestationalAgeAtPresent: 0,
        nextSteps: 'string',
        recommendedFuDelay: 'string',
    },
}
