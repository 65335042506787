import female from '../assets/icons/female.svg'
import male from '../assets/icons/male.svg'
import flag from '../assets/icons/flag.svg'
import flag_dark from '../assets/icons/flag_dark.svg'
import flags from '../assets/icons/flags.svg'
import cardio from '../assets/icons/cardio.svg'
import tooth from '../assets/icons/tooth.svg'
import urology from '../assets/icons/urology.svg'
import severity_high from '../assets/icons/severity_high.svg'
import severity_medium from '../assets/icons/severity_medium.svg'
import filter_arrows from '../assets/icons/filter_arrows.svg'
import arrow from '../assets/icons/arrow.svg'
import arrow_down from '../assets/icons/arrow_down.svg'
import download from '../assets/icons/download.svg'
import person from '../assets/icons/person.svg'
import boarding from '../assets/icons/boarding.svg'
import delays_flag from '../assets/icons/delays_flag.svg'
import clock_circle from '../assets/icons/clock_circle.svg'
import logo_bg_login from '../assets/icons/logo_bg_login.svg'
import sagit_login_main from '../assets/icons/sagit_login_main.svg'
import logo_placeholder from '../assets/icons/logo_placeholder.svg'
import attention from '../assets/icons/attention.svg'
import avatar from '../assets/icons/avatar.svg'
import sagit_labs_logo from '../assets/icons/sagit_labs_logo.svg'
import camera from '../assets/icons/camera.svg'
import flask from '../assets/icons/flask.svg'
import checkbox from '../assets/icons/checkbox.svg'
import checked from '../assets/icons/checked.svg'
import flags_light from '../assets/icons/flags_light.svg'
import doctor from '../assets/icons/doctor.svg'
import plus from '../assets/icons/plus.svg'
import location from '../assets/icons/location.svg'
import people from '../assets/icons/people.svg'
import oxy from '../assets/icons/oxy.svg'
import temperature from '../assets/icons/temperature.svg'
import bp from '../assets/icons/bp.svg'
import hr from '../assets/icons/hr.svg'
import peal from '../assets/icons/peal.svg'
import flask_blue from '../assets/icons/flask_blue.svg'
import camera_blue from '../assets/icons/camera_blue.svg'
import doctor_blue from '../assets/icons/doctor_blue.svg'
import success from '../assets/icons/success.svg'
import error_saved from '../assets/icons/error_saved.svg'
import minus from '../assets/icons/minus.svg'
import attention_dark from '../assets/icons/attention_dark.svg'
import success_arrow from '../assets/icons/success_arrow.svg'
import people_blue from '../assets/icons/people_blue.svg'
import dropdown_arrow from '../assets/icons/dropdown_arrow.svg'
import radio from '../assets/icons/radio.svg'
import pencil from '../assets/icons/pencil.svg'
import user_from from '../assets/icons/user_from.svg'
import user_to from '../assets/icons/user_to.svg'
import plus_huddle from '../assets/icons/plus_huddle.svg'
import calendar from '../assets/icons/calendar.svg'
import arrow_black from '../assets/icons/arrow_black.svg'
import message from '../assets/icons/message.svg'
import open_chat from '../assets/icons/open_chat.svg'
import send from '../assets/icons/send.svg'
import cancell from '../assets/icons/cancell.svg'
import checked_blue from '../assets/icons/checked_blue.svg'
import flag_blue from '../assets/icons/flag_blue.svg'
import location_default from '../assets/icons/location_default.svg'
import filter_funnel from '../assets/icons/filter_funnel.svg'
import filter_alphabet from '../assets/icons/filter_alphabet.svg'
import search_icon from '../assets/icons/search_icon.webp'
import checkbox_selected from '../assets/icons/checkbox_selected.svg'
import filter_funnel_blue from '../assets/icons/filter_funnel_blue.svg'
import black_person from '../assets/icons/black_person.svg'
import accordion_open_arrow from '../assets/icons/accordion_open_arrow.svg'
import lamp_on_blue from '../assets/icons/lamp_on_blue.svg'
import time_on_lightgreen from '../assets/icons/time_on_lightgreen.svg'
import time_calendar_marine from '../assets/icons/time_calendar_marine.svg'
import sand_clock_not_urgent from '../assets/icons/sand_clock_not_urgent.svg'
import sand_clock_mid_urgent from '../assets/icons/sand_clock_mid_urgent.svg'
import sand_clock_high_urgent from '../assets/icons/sand_clock_high_urgent.svg'
import lamp_on_white from '../assets/icons/lamp_on_white.svg'
import dropper from '../assets/icons/dropper.svg'
import er_lamp from '../assets/icons/er_lamp.svg'
import low_range_line from '../assets/icons/low_range_line.svg'
import high_range_line from '../assets/icons/high_range_line.svg'
import waiting_high from '../assets/icons/waiting_high.svg'
import waiting_mid from '../assets/icons/waiting_mid.svg'
import vector from '../assets/icons/vector.svg'
import megaphone from '../assets/icons/megaphone.svg'
import alarm_clock from '../assets/icons/alarm_clock.svg'
import bell from '../assets/icons/bell.svg'
import bell_grey from '../assets/icons/bell_grey.svg'
import bell_white from '../assets/icons/bell_white.svg'
import shield from '../assets/icons/shield.svg'
import message_unread from '../assets/icons/message_unread.svg'
import message_opened from '../assets/icons/message_opened.svg'
import telephone from '../assets/icons/telephone.svg'
import scrub_chart from '../assets/icons/scrub_chart.svg'
import back_arrow from '../assets/icons/back_arrow.svg'
import bulb from '../assets/icons/bulb.svg'
import person_2 from '../assets/icons/person_2.svg'
import person_3 from '../assets/icons/person_3.svg'
import surgery from '../assets/icons/surgery.svg'
import psychiatry from '../assets/icons/psychiatry.svg'
import paliative_care from '../assets/icons/paliative_care.svg'
import internal_medicine from '../assets/icons/internal_medicine.svg'
import home_care from '../assets/icons/home_care.svg'
import geriatrics from '../assets/icons/geriatrics.svg'
import family_medicine from '../assets/icons/family_medicine.svg'
import cardiology from '../assets/icons/cardiology.svg'
import face_happy from '../assets/icons/face_happy.svg'
import flame from '../assets/icons/flame.svg'
import home from '../assets/icons/home.svg'
import arrow_range_down from '../assets/icons/arrow_range_down.svg'
import arrow_range_up from '../assets/icons/arrow_range_up.svg'
import check_box_checked from '../assets/icons/check_box_checked.svg'
import check_box from '../assets/icons/check_box.svg'
import sort_arrow_up from '../assets/icons/sort_arrow_up.svg'
import sort_arrow_down from '../assets/icons/sort_arrow_down.svg'
import question_mark from '../assets/icons/question_mark.svg'
import trend_down from '../assets/icons/trend_down.svg'
import trend_up from '../assets/icons/trend_up.svg'
import rectangle from '../assets/icons/rectangle.svg'
import mail_unread from '../assets/icons/mail_unread.svg'
import unknown_gender from '../assets/icons/unknown_gender.svg'
import male_icon from '../assets/icons/male_icon.svg'
import female_icon from '../assets/icons/female_icon.svg'
import indicator_green from '../assets/icons/indicator_green.svg'
import indicator_grey from '../assets/icons/indicator_grey.svg'
import indicator_orange from '../assets/icons/indicator_orange.svg'
import indicator_red from '../assets/icons/indicator_red.svg'
import indicator_blue from '../assets/icons/indicator_blue.svg'
import cardio_icon from '../assets/icons/cardio_icon.svg'
// import sidebar_poligon from '../assets/icons/sidebar_poligon.svg'
import ai_button from '../assets/icons/ai_button.svg'
import face_sad from '../assets/icons/face_sad.svg'
import logo_text_top from '../assets/icons/logo_text_top.svg'
import logo_text_bottom from '../assets/icons/logo_text_bottom.svg'
import logo_tree from '../assets/icons/logo_tree.svg'
import ftu from '../assets/icons/ftu.svg'
import critical from '../assets/icons/critical.svg'
import oncology from '../assets/icons/oncology.svg'
import a_bottom from '../assets/icons/a_bottom.svg'
import doctor_2 from '../assets/icons/doctor_2.svg'
import face_neutral from '../assets/icons/face_neutral.svg'
import info from '../assets/icons/info.svg'
import warning from '../assets/icons/warning.svg'
import warning_grey from '../assets/icons/warning_grey.svg'
import bed from '../assets/icons/bed.svg'
import chevron from '../assets/icons/chevron.svg'
import indicator_light_blue from '../assets/icons/indicator_light_blue.svg'
import feedback from '../assets/icons/feedback.svg'
import lab from '../assets/icons/lab.svg'
import success_check from '../assets/icons/success_check.svg'
import sand_clock_waitining from '../assets/icons/sand_clock_waitining.svg'
import plus_green from '../assets/icons/plus_green.svg'
import minus_red from '../assets/icons/minus_red.svg'
import info_action from '../assets/icons/info_action.svg'
import isolation from '../assets/icons/isolation.svg'
import close from '../assets/icons/close.svg'
import nurse from '../assets/icons/nurse.svg'
import offPatient from '../assets/icons/offPatient.svg'

export const icons = {
    hr,
    bp,
    oxy,
    male,
    peal,
    plus,
    flag,
    send,
    flags,
    tooth,
    radio,
    arrow,
    arrow_down,
    flask,
    minus,
    cardio,
    pencil,
    female,
    doctor,
    camera,
    people,
    person,
    avatar,
    er_lamp,
    urology,
    message,
    user_to,
    cancell,
    success,
    dropper,
    checked,
    calendar,
    checkbox,
    location,
    download,
    boarding,
    flag_blue,
    user_from,
    attention,
    open_chat,
    flag_dark,
    flask_blue,
    waiting_mid,
    delays_flag,
    arrow_black,
    people_blue,
    plus_huddle,
    error_saved,
    camera_blue,
    doctor_blue,
    temperature,
    flags_light,
    search_icon,
    waiting_high,
    clock_circle,
    black_person,
    lamp_on_blue,
    checked_blue,
    filter_funnel,
    filter_arrows,
    success_arrow,
    logo_bg_login,
    severity_high,
    lamp_on_white,
    attention_dark,
    low_range_line,
    dropdown_arrow,
    high_range_line,
    severity_medium,
    filter_alphabet,
    sagit_labs_logo,
    sagit_login_main,
    location_default,
    logo_placeholder,
    checkbox_selected,
    filter_funnel_blue,
    time_on_lightgreen,
    accordion_open_arrow,
    time_calendar_marine,
    sand_clock_not_urgent,
    sand_clock_mid_urgent,
    sand_clock_high_urgent,
    alarm_clock,
    bell,
    bell_grey,
    bell_white,
    shield,
    message_unread,
    message_opened,
    vector,
    megaphone,
    telephone,
    scrub_chart,
    back_arrow,
    bulb,
    person_2,
    person_3,
    surgery,
    psychiatry,
    paliative_care,
    internal_medicine,
    home_care,
    geriatrics,
    family_medicine,
    cardiology,
    face_happy,
    flame,
    home,
    arrow_range_down,
    arrow_range_up,
    check_box_checked,
    check_box,
    sort_arrow_down,
    sort_arrow_up,
    question_mark,
    rectangle,
    trend_down,
    trend_up,
    mail_unread,
    unknown_gender,
    male_icon,
    female_icon,
    indicator_grey,
    indicator_orange,
    indicator_green,
    indicator_red,
    indicator_blue,
    indicator_light_blue,
    cardio_icon,
    face_sad,
    face_neutral,
    // sidebar_poligon,
    logo_tree,
    logo_text_bottom,
    logo_text_top,
    ai_button,
    ftu,
    critical,
    oncology,
    a_bottom,
    info,
    info_action,
    isolation,
    doctor_2,
    warning,
    warning_grey,
    bed,
    chevron,
    feedback,
    lab,
    success_check,
    sand_clock_waitining,
    minus_red,
    plus_green,
    close,
    nurse,
    offPatient,
    Tooth: tooth,
    Urology: urology,
    Cardiac: cardio,
    Cardiology: cardio,
    Gastroenterology: cardio,
}
