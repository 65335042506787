import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { selectToken } from '../redux/rtk/auth/selectors'
import { selectActiveUserRole } from '../redux/rtk/user/selectors'
import { selectDashboardPatientModalPopup } from '../redux/rtk/patientModal/selectors'
import {
    getUpdatesForPatient,
    getUpdatesForPatients,
} from '../redux/rtk/patientsTable/asyncAction'
import { selectDashboardPopupPatientId } from '../redux/rtk/patientsTable/selectors'
import { useAuth } from 'react-oidc-context'

export default function useFetchUpdates() {
    const { isAuthenticated } = useAuth()
    const dispatch = useDispatch()

    const dashboardPatientModalPopup = useSelector(
        selectDashboardPatientModalPopup
    )
    const dashboardPopupPatientId = useSelector(selectDashboardPopupPatientId)
    const userToken = useSelector(selectToken)
    const activeUserRole = useSelector(selectActiveUserRole)
    const getUpdates = (
        fetchDate: any,
        patientId: string | number | null,
        getUpdatesAction: string
    ) => {
        if (!isAuthenticated) return
        const options = {
            token: userToken,
            after: fetchDate,
            patientId: dashboardPopupPatientId,
            role: activeUserRole,
        }
        if (fetchDate?.length) {
            dashboardPatientModalPopup && dashboardPopupPatientId
                ? dispatch(getUpdatesForPatient(options))
                : dispatch(getUpdatesForPatients(options))
        }
    }
    return getUpdates
}
