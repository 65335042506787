import React from 'react'
import styles from './CustomButton.module.scss'
import { Button, ButtonProps } from '@mantine/core'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../redux/rtk/user/selectors'

interface CustomButtonProps extends ButtonProps {
    customVariant?: 'primary' | 'secondary'
    buttonClassName?: string
    customStyle?: React.CSSProperties
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    // Add more props as needed
}

const CustomButton: React.FC<CustomButtonProps> = ({
    children,
    buttonClassName,
    customVariant,
    customStyle,
    onClick,
    disabled,
    ...props
}) => {
    const theme = useSelector(selectTheme)
    const stylesObj = {
        primary: {
            backgroundColor:
                'linear-gradient(102deg, #1970B9 8.73%, #0987B1 91.4%)',
            color: '#fff',
        },
        secondary: {
            backgroundColor: '#f5f5f5',
            color: '#333',
        },
    }

    const style = {
        padding: '10px',
        border: 'none',
        backgroundColor: disabled ? '#C4C4C4' : 'blue',
        color: disabled ? '#909FAE' : 'white',
    }

    const className = `button_${customVariant}`

    return (
        <Button
            classNames={{
                root: buttonClassName
                    ? `${styles.button} ${buttonClassName}`
                    : `${styles.button} ${styles[className]}`,
            }}
            style={customStyle}
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            {children}
        </Button>
    )
}

export default CustomButton
