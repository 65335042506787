import { createAxiosInstance } from './axiosInstance'

export function env(name: string, defaultValue?: string): string {
    const value = process.env[name]

    if (!value) {
        if (defaultValue !== undefined) {
            return defaultValue
        } else {
            console.warn(
                `Missing: process.env['${name}']. Using default value if provided.`
            )
            return ''
        }
    }

    return value
}

export const patientsTableDataURL = env('REACT_APP_PATIENTS_DATA')
export const usersBaseURL = env('REACT_APP_MAISHA_USERS_API')
export const tasksBaseURL = env('REACT_APP_MAISHA_TASKS_API')
export const baseURL = env('REACT_APP_API_HOST')
export const labsBaseURL = env('REACT_APP_MAISHA_LABS_API')
export const alertsBaseURL = env('REACT_APP_MAISHA_ALERTS_API')
export const departmentTabsURL = env('REACT_APP_MAISHA_DEPARTMENT_API')
export const nursingBaseUrl = env('REACT_APP_MAISHA_NURSING_API')
export const labsEndBaseUrl = env('REACT_APP_MAISHA_LABS_END_API')
export const nsaBaseUrl = env('REACT_APP_MAISHA_NSA_API')
export const criticalCareBaseUrl = env('REACT_APP_CRITICAL_API')
export const backendApiUrl = env('REACT_APP_SCRUBCHART_API_URL')

export const API = createAxiosInstance(baseURL, 'json')
export const APIDATA = createAxiosInstance(patientsTableDataURL, 'json')
export const APIDATABLOB = createAxiosInstance(patientsTableDataURL, 'blob')
export const APIUSERS = createAxiosInstance(usersBaseURL, 'json')
export const TASKSAPIDATA = createAxiosInstance(tasksBaseURL, 'json')
export const LABSAPIDATA = createAxiosInstance(labsBaseURL, 'json')
export const ALERTSAPIDATA = createAxiosInstance(alertsBaseURL, 'json')
export const DEPARTMENTAPIDATA = createAxiosInstance(departmentTabsURL, 'json')
export const NURSINGAPIDATA = createAxiosInstance(nursingBaseUrl, 'json')
export const LABSENDAPIDATA = createAxiosInstance(labsEndBaseUrl, 'json')
export const NSADAPIDATA = createAxiosInstance(nsaBaseUrl, 'json')
