import { useSelector } from 'react-redux'
import { selectLocale } from 'redux/rtk/user/selectors'
import { useGetTranslationsDataQuery } from 'redux/rtk/wardView/wardViewApi'

const localeMap: Record<string, 'english' | 'french'> = {
    en: 'english',
    fr: 'french',
}

export function useTranslationText() {
    const locale = useSelector(selectLocale)
    const {
        data: translationsResponse,
        isLoading,
        error,
    } = useGetTranslationsDataQuery()

    const getTranslationText = (
        category: string,
        language?: 'english' | 'french'
    ): string | undefined => {
        if (!translationsResponse || !translationsResponse.translations)
            return undefined

        const translation = translationsResponse.translations.find(
            (t) => t.category === category
        )
        if (language) {
            return translation ? translation[language] : category
        }
        return translation ? translation[localeMap[locale]] : category
    }

    return { getTranslationText, isLoading, error }
}
