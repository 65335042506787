import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { patientsTableDataURL } from '../../../utils/API'
import { prepareHeaders } from '../helpers/prepareHeaders'

export const visitsApi = createApi({
    reducerPath: 'visitsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: patientsTableDataURL,
        prepareHeaders,
    }),
    tagTypes: ['System'],

    endpoints: (builder) => ({
        getSystem: builder.query<any, any>({
            query: () => `v1/System`,
            providesTags: [{ type: 'System', id: 'SYSTEM' }],
        }),
    }),
})

export const { useGetSystemQuery, useLazyGetSystemQuery } = visitsApi
