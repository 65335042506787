import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    PatientSaturationsType,
    CurrentVitals,
    PatientHeartratesType,
    PatientBloodpreasuresType,
    PatientTemperaturesType,
} from './type'

import {
    bloodPreasure_MOCK,
    currentVitals_MOCK,
    heartRate_MOCK,
    saturation_MOCK,
    temperature_MOCK,
} from '../../../utils/__Mocks__/VitalsMockData'
import { prepareHeaders } from '../helpers/prepareHeaders'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

export const newVisitsApi = createApi({
    reducerPath: 'newVisitsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SCRUBCHART_API_URL}/api/v1/`,
        prepareHeaders,
    }),

    endpoints: (builder) => ({
        getSaturations: builder.query<PatientSaturationsType, number | string>({
            query: (visitId) => `visits/${visitId}/saturations`,
            keepUnusedDataFor: 300,
            transformResponse: (
                response: PatientSaturationsType
            ): PatientSaturationsType => {
                if (isDevelopment) {
                    return saturation_MOCK
                }
                return response
            },
        }),
        getTemperatures: builder.query<
            PatientTemperaturesType,
            number | string
        >({
            query: (visitId) => `visits/${visitId}/temperatures`,
            keepUnusedDataFor: 300,
            transformResponse: (
                response: PatientTemperaturesType
            ): PatientTemperaturesType => {
                if (isDevelopment) {
                    return temperature_MOCK
                }
                return response
            },
        }),
        getBloodPressures: builder.query<
            PatientBloodpreasuresType,
            number | string
        >({
            query: (visitId) => `visits/${visitId}/blood-pressures`,
            keepUnusedDataFor: 300,
            transformResponse: (
                response: PatientBloodpreasuresType
            ): PatientBloodpreasuresType => {
                if (isDevelopment) {
                    return bloodPreasure_MOCK
                }
                return response
            },
        }),
        getHeartRates: builder.query<PatientHeartratesType, number | string>({
            query: (visitId) => `visits/${visitId}/heart-rates`,
            keepUnusedDataFor: 300,
            transformResponse: (
                response: PatientHeartratesType
            ): PatientHeartratesType => {
                if (isDevelopment) {
                    return heartRate_MOCK
                }
                return response
            },
        }),
        getCurrentVitals: builder.query<CurrentVitals, number | string>({
            query: (visitId) => `visits/${visitId}/current-vitals`,
            keepUnusedDataFor: 300,
            transformResponse: (response: CurrentVitals): CurrentVitals => {
                if (isDevelopment) {
                    return currentVitals_MOCK
                }
                return response
            },
        }),
    }),
})

export const {
    useGetBloodPressuresQuery,
    useGetCurrentVitalsQuery,
    useGetHeartRatesQuery,
    useGetSaturationsQuery,
    useGetTemperaturesQuery,
} = newVisitsApi
