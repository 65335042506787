import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Text } from '@mantine/core'

import styles from './LanguageBlock.module.scss'
import {
    selectActiveUserRole,
    selectLocale,
    selectTheme,
} from '../../../../redux/rtk/user/selectors'
import { setUpdatePreferences } from '../../../../redux/rtk/user/asyncAction'
import { useAuth } from 'react-oidc-context'

const LanguageBlock: React.FC = () => {
    const dispatch = useDispatch()
    const theme = useSelector(selectTheme)
    const locale = useSelector(selectLocale)
    const activeUserRole = useSelector(selectActiveUserRole)

    const { isAuthenticated } = useAuth()

    const changedLanguage = useCallback(
        (newLocale: string) => {
            if (!isAuthenticated) return

            dispatch(
                setUpdatePreferences({
                    language: newLocale === 'en' ? 0 : 1,
                    theme: theme === 'light' ? 0 : 1,
                    role: activeUserRole,
                })
            )
        },
        [dispatch, theme, activeUserRole]
    )

    const languageButton = useMemo(
        () => (language: string) => {
            const isActive = locale === language
            return (
                <Text
                    size="xl"
                    fw={600}
                    variant={isActive ? '' : 'gradient'}
                    tt="uppercase"
                    className={`${styles.lang__block__button} ${
                        isActive
                            ? styles.lang__block__button_active
                            : styles.lang__block__button_inactive
                    }`}
                    style={{
                        cursor: isActive ? 'default' : 'pointer',
                    }}
                    onClick={() => changedLanguage(language)}
                >
                    {language}
                </Text>
            )
        },
        [locale, theme, changedLanguage]
    )

    return (
        <>
            <Menu.Item className={styles.lang__block} key="Change_Language">
                <div className={`${styles.lang__block__item}`}>
                    {languageButton('en')}
                    {languageButton('fr')}
                </div>
            </Menu.Item>
        </>
    )
}

export default LanguageBlock
