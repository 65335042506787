import { NsaOperationalResponce } from 'redux/rtk/nsa/types'

export const NSA_DEPARTMENT_MOCK_DATA: NsaOperationalResponce = [
    {
        serviceName: 'Cardiology',
        patientCount: 5,
        patientsWithDelayCount: 4,
        longestWaitingTime: 86,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 5,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'CSUR',
    },
    // {
    //     serviceName: 'PACU',
    //     patientCount: 0,
    //     patientsWithDelayCount: 0,
    //     longestWaitingTime: 0,
    //     countWaitingTimeByRange: [
    //         {
    //             range_name: '0-5d',
    //             count_by_range: 0,
    //         },
    //         {
    //             range_name: '>10d',
    //             count_by_range: 0,
    //         },
    //         {
    //             range_name: '5-10d',
    //             count_by_range: 0,
    //         },
    //     ],
    //     lastModifiedDate: '2024-10-17T07:27:09.819056',
    //     categoriesByService: [],
    // },
    {
        serviceName: 'IM non Teach',
        patientCount: 0,
        patientsWithDelayCount: 0,
        longestWaitingTime: 0,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 0,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [],
        unitCensusCode: 'IM_NON_TEACH',
    },
    {
        serviceName: 'Surgery',
        patientCount: 14,
        patientsWithDelayCount: 9,
        longestWaitingTime: 250,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 12,
            },
            {
                range_name: '5-10d',
                count_by_range: 2,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'CHIR',
    },
    {
        serviceName: 'Palliative',
        patientCount: 2,
        patientsWithDelayCount: 2,
        longestWaitingTime: 43,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 2,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'PALL',
    },
    {
        serviceName: 'Special Units',
        patientCount: 0,
        patientsWithDelayCount: 0,
        longestWaitingTime: 0,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 0,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [],
        unitCensusCode: 'SPECIAL_UNITS',
    },
    {
        serviceName: 'Oncology',
        patientCount: 7,
        patientsWithDelayCount: 4,
        longestWaitingTime: 132,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 6,
            },
            {
                range_name: '5-10d',
                count_by_range: 1,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'ONC',
    },
    {
        serviceName: 'IM Teach',
        patientCount: 6,
        patientsWithDelayCount: 3,
        longestWaitingTime: 156,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 6,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'IM_TEACH',
    },
    {
        serviceName: 'HAD',
        patientCount: 0,
        patientsWithDelayCount: 0,
        longestWaitingTime: 0,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 0,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [],
        unitCensusCode: 'HAD',
    },
    {
        serviceName: 'Geriatrics',
        patientCount: 48,
        patientsWithDelayCount: 40,
        longestWaitingTime: 569,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 48,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'GER',
    },
    {
        serviceName: 'MSICU',
        patientCount: 0,
        patientsWithDelayCount: 0,
        longestWaitingTime: 0,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 0,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [],
        unitCensusCode: 'SI',
    },
    {
        serviceName: 'Psychiatry',
        patientCount: 9,
        patientsWithDelayCount: 6,
        longestWaitingTime: 531,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 9,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'PSY',
    },
    {
        serviceName: 'SSU',
        patientCount: 3,
        patientsWithDelayCount: 3,
        longestWaitingTime: 30,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 3,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'SSU',
    },
    {
        serviceName: 'Family Med',
        patientCount: 2,
        patientsWithDelayCount: 2,
        longestWaitingTime: 101,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 2,
            },
            {
                range_name: '5-10d',
                count_by_range: 0,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'FAM',
    },
    {
        serviceName: 'Neurology',
        patientCount: 11,
        patientsWithDelayCount: 7,
        longestWaitingTime: 126,
        countWaitingTimeByRange: [
            {
                range_name: '0-5d',
                count_by_range: 0,
            },
            {
                range_name: '>10d',
                count_by_range: 10,
            },
            {
                range_name: '5-10d',
                count_by_range: 1,
            },
        ],
        lastModifiedDate: '2024-10-17T07:27:09.819056',
        categoriesByService: [
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
            {
                nsaCategory: null,
                countCategoriesOverServicesAndUnits: 0,
            },
        ],
        unitCensusCode: 'NEUR',
    },
]
