import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { patientsTableDataURL } from '../../../utils/API'
import { responceMock } from '../../../utils/__Mocks__/EdData'
import { admissionsPetientsMock } from '../../../utils/__Mocks__/EDLivePatients'
import { EdCorporateData, EdWardResponse } from './types'
import { WARD_PATIENTS_MOCK } from 'utils/__Mocks__/wardViewMock'
import { createQueryFn } from '../helpers/createQueryFn'
import { prepareHeaders } from '../helpers/prepareHeaders'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'development'

export const edLiveApi = createApi({
    reducerPath: 'edLiveApi',
    baseQuery: fetchBaseQuery({
        baseUrl: patientsTableDataURL,
        prepareHeaders,
    }),

    tagTypes: ['EdPatients', 'EdCorporate', 'WARD_PATIENTS'],
    endpoints: (builder) => ({
        getEdPatients: builder.query<EdWardResponse, any>({
            query: () => `/v1/ED/edWard`,
            providesTags: [
                {
                    type: 'EdPatients',
                    id: 'ED_LIVE_PATIENTS',
                },
            ],
            // This is for mocking data
            transformResponse: (response: EdWardResponse): EdWardResponse => {
                if (isDevelopment) {
                    return admissionsPetientsMock
                }
                return response
            },
        }),
        getEdCorporate: builder.query<EdCorporateData, any>({
            query: () => `/v1/ED/edCorporate`,
            providesTags: [{ type: 'EdPatients', id: 'ED_LIVE_PATIENTS' }],
            // This is for mocking data
            transformResponse: (response: EdCorporateData): EdCorporateData => {
                if (isDevelopment) {
                    return responceMock
                }
                return response
            },
        }),
        //Temporary WARD_PATIENTS_MOCK
        getWardView: builder.query<any, any>({
            // query: () => `/ward`,
            // providesTags: [{ type: 'WARD_PATIENTS', id: 'WARD_PATIENTS' }],
            // // This is for mocking data
            // transformResponse: (response: any): any => {
            //     if (isDevelopment) {
            //         return WARD_PATIENTS_MOCK
            //     }
            //     return response
            // },
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${patientsTableDataURL}/ward/Cardiology`,
                () => WARD_PATIENTS_MOCK
            ),
        }),
    }),
})

export const {
    useGetEdPatientsQuery,
    useGetEdCorporateQuery,
    useLazyGetEdPatientsQuery,
    useLazyGetEdCorporateQuery,
    useGetWardViewQuery,
} = edLiveApi
