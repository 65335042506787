import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import styles from './UserRolesItems.module.scss'
import {
    selectActiveUserRole,
    selectLocale,
    selectTheme,
    selectUserDetails,
} from '../../../../redux/rtk/user/selectors'
import { setUpdatePreferences } from '../../../../redux/rtk/user/asyncAction'
import { resetFilter } from '../../../../redux/rtk/filter/filterSlice'
import { setPatientsDataAPI } from '../../../../redux/rtk/patientsTable/patientsTableSlice'
import { setAtiveUserRole } from '../../../../redux/rtk/user/userSlice'
import { useAuth } from 'react-oidc-context'

const resetStoreOptions = [
    'customFilter',
    'patientsDataAPI',
    'hasNextPage',
    'pageNumber',
    'isLoading',
    'SortBy',
    'SortOrder',
    'departmenSearch',
    'patientNameSearch',
    'hospitalIdSearch',
]

const UserRolesItems: React.FC = () => {
    const dispatch = useDispatch()
    const { isAuthenticated } = useAuth()
    const { t } = useTranslation()
    const theme = useSelector(selectTheme)
    const locale = useSelector(selectLocale)
    const activeUserRole = useSelector(selectActiveUserRole)
    const userDetails = useSelector(selectUserDetails)
    const userRoles = userDetails?.roles
    // const activeUserRole = 'doctor'
    // const userRoles = ['manager', 'doctor', 'terminator', 'misha']

    const changedRole = useCallback(
        (role: string): void => {
            if (!isAuthenticated) return

            dispatch(resetFilter(resetStoreOptions))
            dispatch(setPatientsDataAPI([]))
            dispatch(
                setUpdatePreferences({
                    language: locale === 'en' ? 0 : 1,
                    theme: theme === 'light' ? 0 : 1,
                    role: role,
                })
            )
            dispatch(setAtiveUserRole(role))
        },
        [locale, dispatch, theme]
    )

    const menuItems = useMemo(
        () =>
            userRoles.map((role) => ({
                role,
                isNotActive: activeUserRole !== role,
            })),
        [userRoles, activeUserRole]
    )

    return (
        <>
            <Menu
                position="right-start"
                trigger="hover"
                offset={15}
                transitionProps={{ transition: 'rotate-right', duration: 150 }}
                classNames={{
                    dropdown: `${styles.submenu_body} ${
                        styles[theme + '-theme']
                    }`,
                    item: styles.submenu__item,
                    label: styles.submenu__label,
                }}
            >
                <Menu.Target>
                    <div className={`${styles.submenu__target} `}>
                        <span className={`${styles.submenu__target__label} `}>
                            {t('browse as')}
                        </span>
                        <span className={styles.submenu__target__border_block}>
                            {t(`${activeUserRole}`) || ''}
                        </span>
                    </div>
                </Menu.Target>
                <Menu.Divider />
                <Menu.Dropdown>
                    {menuItems.map(({ role, isNotActive }, index) => (
                        <React.Fragment key={role}>
                            <Menu.Item
                                className={styles.submenu__item}
                                onMouseDown={() => changedRole(role)}
                            >
                                <Text
                                    size="xl"
                                    fw={600}
                                    variant={isNotActive ? 'gradient' : ''}
                                    className={
                                        isNotActive
                                            ? styles.submenu__item_inactive
                                            : styles.submenu__item_active
                                    }
                                >
                                    {t(role)}
                                </Text>
                            </Menu.Item>
                            {index !== menuItems.length - 1 && <Menu.Divider />}
                        </React.Fragment>
                    ))}
                </Menu.Dropdown>
            </Menu>
        </>
    )
}

export default UserRolesItems
