import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Patient } from '../wardView/types'
import { MRT_ColumnFiltersState } from 'mantine-react-table'
type ActiveFilter = {
    id: string
    value: unknown
}
interface ManagementState {
    resizeWH: {
        width: number
        height: number
    }
    selectedWardId: number | null
    doctorNamesList: string[]
    selectedDoctor: string | null | any
    wardFilters: MRT_ColumnFiltersState
    filteredWards: Patient[]
    selectedPatientId: string | null
}

const initialState: ManagementState = {
    resizeWH: {
        width: 400,
        height: 200,
    },
    selectedWardId: null,
    doctorNamesList: [],
    selectedDoctor: null,
    wardFilters: [],
    filteredWards: [],
    selectedPatientId: null,
}

const managemenSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {
        setResizeWH(state, action) {
            state.resizeWH = action.payload
        },
        setSelectedWardId: (state, action: PayloadAction<number | null>) => {
            state.selectedWardId = action.payload
        },
        clearSelectedWardId: (state) => {
            state.selectedWardId = null
        },
        setDoctorNamesList: (state, action: PayloadAction<string[] | []>) => {
            state.doctorNamesList = action.payload
        },
        setSelectedDoctor: (state, action: PayloadAction<string | null>) => {
            state.selectedDoctor = action.payload
        },
        setWardFilters: (state, action: PayloadAction<ActiveFilter[]>) => {
            const incomingFilters = action.payload
            incomingFilters.forEach((newFilter) => {
                const { id, value } = newFilter

                // Check if the incoming filter's value is null
                if (value === null) {
                    // Remove the filter with this id from the state
                    state.wardFilters = state.wardFilters.filter(
                        (filter) => filter.id !== id
                    )
                } else {
                    // Find the index of the filter by id
                    const existingFilterIndex = state.wardFilters.findIndex(
                        (filter) => filter.id === id
                    )

                    if (existingFilterIndex !== -1) {
                        // If the filter exists and has the same value, remove it
                        state.wardFilters[existingFilterIndex].value = value
                    } else {
                        // If the filter does not exist, add it
                        state.wardFilters.push({ id, value })
                    }
                }
            })
        },
        clearWardFilters: (state) => {
            state.wardFilters = []
        },
        setFilteredWards: (state, action: PayloadAction<Patient[]>) => {
            state.filteredWards = action.payload
        },
        clearFilteredWards: (state, action: PayloadAction<Patient[]>) => {
            state.filteredWards = []
        },
        setSelectedPatientId: (state, action: PayloadAction<string | null>) => {
            state.selectedPatientId = action.payload
        },
        clearSelectedPatientId: (state) => {
            state.selectedPatientId = null
        },
    },
    extraReducers: (builder) => {},
})

export const {
    setResizeWH,
    setSelectedWardId,
    clearSelectedWardId,
    setDoctorNamesList,
    setSelectedDoctor,
    setWardFilters,
    clearWardFilters,
    setFilteredWards,
    clearFilteredWards,
    setSelectedPatientId,
    clearSelectedPatientId,
} = managemenSlice.actions

export default managemenSlice.reducer
